import React from 'react';

const Merch = () => {
  return <>

<div  className="px-40 flex flex-1 justify-center py-5">
          <div  className="layout-content-container flex flex-col max-w-[960px] flex-1">
            <div  className="flex flex-wrap gap-2 p-4">
              <a  className="text-[#97704e] text-base font-medium leading-normal" href="#">Home</a>
              <span  className="text-[#97704e] text-base font-medium leading-normal">/</span>
              <span  className="text-[#1b140e] text-base font-medium leading-normal">Store</span>
            </div>
            <div  className="@container">
              <div  className="@[480px]:p-4">
                <div
                   className="flex min-h-[480px] flex-col gap-6 bg-cover bg-center bg-no-repeat @[480px]:gap-8 @[480px]:rounded-xl items-center justify-center p-4"
                   style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.4) 100%), url("https://cdn.usegalileo.ai/sdxl10/b85b2442-0718-472c-9265-e7a88e622fac.png")` }}
                >
                  <h1
                     className="text-white text-4xl font-black leading-tight tracking-[-0.033em] @[480px]:text-5xl @[480px]:font-black @[480px]:leading-tight @[480px]:tracking-[-0.033em] text-center"
                  >
                    Moose Lodge 1717 Merchandise
                  </h1>
                  <button
                     className="flex min-w-[84px] max-w-[480px] cursor-pointer items-center justify-center overflow-hidden rounded-full h-10 px-4 @[480px]:h-12 @[480px]:px-5 bg-[#e78027] text-[#1b140e] text-sm font-bold leading-normal tracking-[0.015em] @[480px]:text-base @[480px]:font-bold @[480px]:leading-normal @[480px]:tracking-[0.015em]"
                  >
                    <span  className="truncate">Shop Now</span>
                  </button>
                </div>
              </div>
            </div>
            <h2  className="text-[#1b140e] text-[22px] font-bold leading-tight tracking-[-0.015em] px-4 pb-3 pt-5">Featured Collections</h2>
            <div  className="grid grid-cols-[repeat(auto-fit,minmax(158px,1fr))] gap-3 p-4">
              <div  className="flex flex-col gap-3 pb-3">
                <div
                   className="w-full bg-center bg-no-repeat aspect-square bg-cover rounded-xl"
                   style={{ backgroundImage: 'url("https://cdn.usegalileo.ai/sdxl10/41517d18-c157-473f-a14d-2b1624e0ac5c.png")' }}
                ></div>
                <p  className="text-[#1b140e] text-base font-medium leading-normal">Moose Lodge 1717 T-Shirts</p>
              </div>
              <div  className="flex flex-col gap-3 pb-3">
                <div
                   className="w-full bg-center bg-no-repeat aspect-square bg-cover rounded-xl"
                   style={{ backgroundImage: 'url("https://cdn.usegalileo.ai/sdxl10/bc1f3c51-d59a-4de4-ab89-392c4d6638fd.png")' }}
                ></div>
                <p  className="text-[#1b140e] text-base font-medium leading-normal">Moose Lodge 1717 Hats</p>
              </div>
              <div  className="flex flex-col gap-3 pb-3">
                <div
                   className="w-full bg-center bg-no-repeat aspect-square bg-cover rounded-xl"
                   style={{ backgroundImage: 'url("https://cdn.usegalileo.ai/sdxl10/aec272d0-0f15-42fc-8c85-96d0df06116b.png")' }}
                ></div>
                <p  className="text-[#1b140e] text-base font-medium leading-normal">Moose Lodge 1717 Mugs</p>
              </div>
              <div  className="flex flex-col gap-3 pb-3">
                <div
                   className="w-full bg-center bg-no-repeat aspect-square bg-cover rounded-xl"
                   style={{ backgroundImage: 'url("https://cdn.usegalileo.ai/sdxl10/82a12ea6-2f2c-490c-b925-02271b406cfd.png")' }}
                ></div>
                <p  className="text-[#1b140e] text-base font-medium leading-normal">Moose Lodge 1717 Aprons</p>
              </div>
              <div  className="flex flex-col gap-3 pb-3">
                <div
                   className="w-full bg-center bg-no-repeat aspect-square bg-cover rounded-xl"
                   style={{ backgroundImage: 'url("https://cdn.usegalileo.ai/sdxl10/38fa2f77-b410-4e2b-891d-91b4491a02ba.png")' }}
                ></div>
                <p  className="text-[#1b140e] text-base font-medium leading-normal">Moose Lodge 1717 Totes</p>
              </div>
            </div>
            <h2  className="text-[#1b140e] text-[22px] font-bold leading-tight tracking-[-0.015em] px-4 pb-3 pt-5">New Arrivals</h2>
            <div  className="grid grid-cols-[repeat(auto-fit,minmax(158px,1fr))] gap-3 p-4">
              <div  className="flex flex-col gap-3 pb-3">
                <div
                   className="w-full bg-center bg-no-repeat aspect-square bg-cover rounded-xl"
                   style={{ backgroundImage: 'url("https://cdn.usegalileo.ai/stability/ee282ea5-277e-4519-8c2f-fda05ea78050.png")' }}
                ></div>
                <p  className="text-[#1b140e] text-base font-medium leading-normal">T-Shirt</p>
              </div>
              <div  className="flex flex-col gap-3 pb-3">
                <div
                   className="w-full bg-center bg-no-repeat aspect-square bg-cover rounded-xl"
                   style={{ backgroundImage: 'url("https://cdn.usegalileo.ai/stability/b4c547fa-f28b-419f-a783-8b77c0ee5264.png")' }}
                ></div>
                <p  className="text-[#1b140e] text-base font-medium leading-normal">Cap</p>
              </div>
              <div  className="flex flex-col gap-3 pb-3">
                <div
                   className="w-full bg-center bg-no-repeat aspect-square bg-cover rounded-xl"
                   style={{ backgroundImage: 'url("https://cdn.usegalileo.ai/stability/da09723c-db76-471f-a522-1955cadb2a98.png")' }}
                ></div>
                <p  className="text-[#1b140e] text-base font-medium leading-normal">Mug</p>
              </div>
              <div  className="flex flex-col gap-3 pb-3">
                <div
                   className="w-full bg-center bg-no-repeat aspect-square bg-cover rounded-xl"
                   style={{ backgroundImage: 'url("https://cdn.usegalileo.ai/sdxl10/4f1292bc-4b84-429f-ba2a-f455a4182563.png")' }}
                ></div>
                <p  className="text-[#1b140e] text-base font-medium leading-normal">Apron</p>
              </div>
              <div  className="flex flex-col gap-3 pb-3">
                <div
                   className="w-full bg-center bg-no-repeat aspect-square bg-cover rounded-xl"
                   style={{ backgroundImage: 'url("https://cdn.usegalileo.ai/sdxl10/5dcbb821-f083-4e92-a190-ca8500847398.png")' }}
                ></div>
                <p  className="text-[#1b140e] text-base font-medium leading-normal">Tote</p>
              </div>
            </div>
            <div  className="flex items-center justify-center p-4">
              <a href="#"  className="flex size-10 items-center justify-center">
                <div  className="text-[#1b140e]" data-icon="CaretLeft" data-size="18px" data-weight="regular">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" fill="currentColor" viewBox="0 0 256 256">
                    <path d="M165.66,202.34a8,8,0,0,1-11.32,11.32l-80-80a8,8,0,0,1,0-11.32l80-80a8,8,0,0,1,11.32,11.32L91.31,128Z"></path>
                  </svg>
                </div>
              </a>
              <a  className="text-sm font-bold leading-normal tracking-[0.015em] flex size-10 items-center justify-center text-[#1b140e] rounded-full bg-[#f3ede7]" href="#">1</a>
              <a  className="text-sm font-normal leading-normal flex size-10 items-center justify-center text-[#1b140e] rounded-full" href="#">2</a>
              <a  className="text-sm font-normal leading-normal flex size-10 items-center justify-center text-[#1b140e] rounded-full" href="#">3</a>
              <a  className="text-sm font-normal leading-normal flex size-10 items-center justify-center text-[#1b140e] rounded-full" href="#">4</a>
              <a  className="text-sm font-normal leading-normal flex size-10 items-center justify-center text-[#1b140e] rounded-full" href="#">5</a>
              <a href="#"  className="flex size-10 items-center justify-center">
                <div  className="text-[#1b140e]" data-icon="CaretRight" data-size="18px" data-weight="regular">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" fill="currentColor" viewBox="0 0 256 256">
                    <path d="M181.66,133.66l-80,80a8,8,0,0,1-11.32-11.32L164.69,128,90.34,53.66a8,8,0,0,1,11.32-11.32l80,80A8,8,0,0,1,181.66,133.66Z"></path>
                  </svg>
                </div>
              </a>
            </div>
          </div>
        </div>


  </>;
};

export default Merch;
