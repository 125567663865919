import React from 'react';
import { useEffect, createRef } from 'react';

import { ReactPhotoSphereViewer } from "react-photo-sphere-viewer";


  
const Facilities = () => {


  return <>


  <div class="@container">
              <div class="@[480px]:p-4">
                <div
                  class="flex min-h-[380px] sm:min-h-[380px] md:min-h-[640px]   flex-col gap-6 bg-cover bg-center bg-no-repeat items-start justify-end px-4 pb-10 @[480px]:px-10"
                  style={{ backgroundPositionX: 'center', backgroundPositionY: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.4) 100%), url("img/building-landscaped.jpg` }}
                >
                  <div class="flex flex-col gap-2 text-left">
                    <h1
                      class="text-white text-4xl font-black leading-tight tracking-[-0.033em] @[480px]:text-5xl @[480px]:font-black @[480px]:leading-tight @[480px]:tracking-[-0.033em]"
                    >
                      Moose Lodge 1717
                    </h1>
                    <h2 class="text-white text-sm font-normal leading-normal @[480px]:text-base @[480px]:font-normal @[480px]:leading-normal">
                      A family fraternal organization that supports the community. Moose Lodge 1717 provides a wide range of social and recreational activities for its members.
                    </h2>
                  </div>


                </div>
              </div>
            </div>

 

        <div className="sm:px-1 md:px-20 lg:px-40 justify-center py-5">


            <h2 class="text-[#1b140e] text-[22px] font-bold leading-tight tracking-[-0.015em] px-4 pb-3 pt-5">Facilities</h2>
            <div class="grid grid-cols-[repeat(auto-fit,minmax(158px,1fr))] gap-3 p-4">
              <div class="flex flex-col gap-3 pb-3">
                <div
                  class="w-full bg-center bg-no-repeat aspect-video bg-cover rounded-xl"
                  style={{ backgroundImage: 'url("img/memorial_hall.jpg")' }}
                ></div>
                <div>
                  <p class="text-[#1b140e] text-base font-medium leading-normal">Memorial Hall</p>
                  <p class="text-[#97704e] text-sm font-normal leading-normal">
                    The Donald Hayward Memorial Hall has a spacious, decorated reception hall with a capacity for 236 people. The hall is equipped with a stage, making
                    it an ideal venue for weddings, banquets, and other special events. 
                  </p>
                </div>
              </div>
              <div class="flex flex-col gap-3 pb-3">
                <div
                  class="w-full bg-center bg-no-repeat aspect-video bg-cover rounded-xl"
                  style={{ backgroundImage: 'url("img/pavilion.jpg")' }}
                ></div>
                <div>
                  <p class="text-[#1b140e] text-base font-medium leading-normal">Outdoor Pavilion</p>
                  <p class="text-[#97704e] text-sm font-normal leading-normal">
                    The outdoor pavilion is located next to the outdoor bar and is a popular spot for picnics, barbecues, and other social gatherings. The pavilion has a seating area, a
                    grill, and a bar.
                  </p>
                </div>
              </div>
              <div class="flex flex-col gap-3 pb-3">
                <div class="w-full bg-center bg-no-repeat aspect-video bg-cover rounded-xl" style={{ backgroundImage: 'url("img/IMG_6906.JPG")' }} ></div>
                <div>
                  <p class="text-[#1b140e] text-base font-medium leading-normal">Pool Tables</p>
                  <p class="text-[#97704e] text-sm font-normal leading-normal">The Lodge has two pool tables, which are available for members to use.</p>
                </div>
              </div>
            </div>
            <div class="grid grid-cols-[repeat(auto-fit,minmax(158px,1fr))] gap-3 p-4">
              <div class="flex flex-col gap-3 pb-3">
                <div class="w-full bg-center bg-no-repeat aspect-video bg-cover rounded-xl" style={{ backgroundImage: 'url("img/social_quarters.jpg")' }} ></div>

                <div>
                  <p class="text-[#1b140e] text-base font-medium leading-normal">Social Quarters</p>
                  <p class="text-[#97704e] text-sm font-normal leading-normal">
                    The lounge is a comfortable space to relax and socialize. It has cozy seating, dining tables, TVs, Full Bar, TouchTunes.
                  </p>
                </div>
              </div>
              <div class="flex flex-col gap-3 pb-3">
                <div
                  class="w-full bg-center bg-no-repeat aspect-video bg-cover rounded-xl"
                  style={{ backgroundImage: 'url("img/bar.jpg")' }}
                ></div>
                <div>
                  <p class="text-[#1b140e] text-base font-medium leading-normal">Bar</p>
                  <p class="text-[#97704e] text-sm font-normal leading-normal">
                    The bar offers a variety of beer, wine, and spirits at affordable prices. Members can enjoy their favorite drinks in a friendly, casual atmosphere.
                  </p>
                </div>
              </div>
            </div>



            <ReactPhotoSphereViewer src="img/360/POOL_TABLES.PHOTOSPHERE.jpg" height={'640px'} width={"100%"} className="rounded"></ReactPhotoSphereViewer>
            <br></br>
            <ReactPhotoSphereViewer src="img/360/MEMORIAL_HALL.PHOTOSPHERE.jpg" height={'640px'} width={"100%"}  className="rounded"></ReactPhotoSphereViewer>
            <br></br>
            <ReactPhotoSphereViewer src="img/360/SOCIAL_QUARTERS.PHOTOSPHERE.jpg" height={'640px'} width={"100%"}  className="rounded"></ReactPhotoSphereViewer>
            <br></br>
            <ReactPhotoSphereViewer src="img/360/OUTDOOR_BAR.PHOTOSPHERE.jpg" height={'640px'} width={"100%"}  className="rounded"></ReactPhotoSphereViewer>
            <br></br>
            <ReactPhotoSphereViewer src="img/360/OUTDOOR_PAVILION.PHOTOSPHERE.jpg" height={'640px'} width={"100%"}  className="rounded"></ReactPhotoSphereViewer>
  



</div>

  </>;


};

export default Facilities;
