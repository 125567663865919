import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import MooseText from '../images/moose_lodge_1717_text.svg';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <header className="flex flex-col md:flex-row items-center justify-between border-b border-solid border-b-[#f3ede7]  py-2">
      <div className="flex items-center justify-between w-full md:w-auto px-5">
        <img src={MooseText} alt="Moose Lodge 1717 Logo" className="h-[22px]" />
        <div className="md:hidden">
          <button onClick={toggleMenu} className="cursor-pointer">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
            </svg>
          </button>
        </div>
      </div>

      <div className={`w-full md:flex md:flex-row md:justify-end flex-col md:items-center gap-2 rounded-md ${isMenuOpen ? 'flex' : 'hidden'}`}>
        <div className="flex flex-col md:flex-row md:items-center gap-2 mt-3 md:mt-0 ">
          <NavLink 
            to="/" 
            exact 
            className="rounded-sm text-[#1b140e] text-sm font-medium py-1 px-10 sm:px-4 hover:bg-gray-200 active:bg-gray-300" 
            onClick={closeMenu}
            activeClassName="bg-gray-300"
          >
            Home
          </NavLink>
          <div className="relative cursor-pointer">
            <div onClick={toggleDropdown} className="text-[#1b140e] text-sm font-medium  px-10 sm:px-4">About</div>
            <div className={`${isDropdownOpen ? 'flex' : 'hidden'} flex-col absolute bg-white shadow-md mt-2 rounded-lg z-10 text-sm w-[200px]`}>
              <NavLink 
                to="/officers" 
                className="px-4 py-1 hover:bg-gray-200 active:bg-gray-300" 
                onClick={closeMenu}
                activeClassName="bg-gray-300"
              >
                Lodge Officers
              </NavLink>
              <NavLink 
                to="/history" 
                className="px-4 py-1 hover:bg-gray-200 active:bg-gray-300" 
                onClick={closeMenu}
                activeClassName="bg-gray-300"
              >
                History
              </NavLink>
              <NavLink 
                to="/facilities" 
                className="px-4 py-1 hover:bg-gray-200 active:bg-gray-300" 
                onClick={closeMenu}
                activeClassName="bg-gray-300"
              >
                Our Facilities
              </NavLink>
              <NavLink 
                to="/about" 
                className="px-4 py-1 hover:bg-gray-200 active:bg-gray-300" 
                onClick={closeMenu}
                activeClassName="bg-gray-300"
              >
                Our Mission
              </NavLink>
            </div>
          </div>

          <NavLink 
            to="/calendar" 
            className="rounded-sm text-[#1b140e] text-sm font-medium hover:bg-gray-200 active:bg-gray-300   py-1 px-10 sm:px-4" 
            onClick={closeMenu}
            activeClassName="bg-gray-300"
          >
            Events
          </NavLink>
          <NavLink 
            to="/menu" 
            className="rounded-sm text-[#1b140e] text-sm font-medium hover:bg-gray-200 active:bg-gray-300  py-1 px-10 sm:px-4" 
            onClick={closeMenu}
            activeClassName="bg-gray-300"
          >
            Menu
          </NavLink>
          <NavLink 
            to="/merch" 
            className="rounded-sm text-[#1b140e] text-sm font-medium hover:bg-gray-200 active:bg-gray-300   py-1 px-10 sm:px-4" 
            onClick={closeMenu}
            activeClassName="bg-gray-300"
          >
            Merch
          </NavLink>
          <NavLink 
            to="/contact" 
            className="rounded-sm text-[#1b140e] text-sm font-medium hover:bg-gray-200 active:bg-gray-300  py-1 px-10 sm:px-4" 
            onClick={closeMenu}
            activeClassName="bg-gray-300"
          >
            Contact Us
          </NavLink>
        </div>
      </div>
    </header>
  );
}

export default Header;
