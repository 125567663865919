import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../components/AuthContext';

const AdminLogin = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [statusMessage, setStatusMessage] = useState('');
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const success = await login(username, password);
    if (success) {
      setStatusMessage('Login successful!');
      navigate('/dashboard'); // Redirect to the dashboard after successful login
    } else {
      setStatusMessage('Login failed. Please check your credentials and try again.');
    }
  };

  return (
    <div className="flex flex-wrap lg:flex-no-wrap px-0 md:w-[800px] md:mx-auto mb-20">
      <div className="flex justify-center items-center">
        <img src="./img/securemoose.jpeg" className="w-fit scale-x-[-1] md:rounded-full md:max-w-[220px]" />
      </div>
      <form onSubmit={handleSubmit} className="w-full md:w-auto mx-auto mt-4 p-4 lg:w-full xl:w-2/3">
        <h1 className="text-xl font-bold text-gray-700">Secure Login</h1>
        <p className="text-base mb-2">Please enter your username and password to access the secure area.</p>
        {statusMessage && (
          <div className={`mb-4 text-sm ${statusMessage.includes('successful') ? 'text-green-500' : 'text-red-500'}`}>
            {statusMessage}
          </div>
        )}
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2 hidden" htmlFor="username">
            Username
          </label>
          <input
            type="text"
            name="username"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Username"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-base text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-base text-sm font-bold mb-2 hidden" htmlFor="password">
            Password
          </label>
          <input
            type="password"
            name="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-base text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="flex items-center justify-between">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-base text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Login
          </button>
        </div>
      </form>
    </div>
  );
};

export default AdminLogin;
