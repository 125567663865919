import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode'; // Correct the import statement

const AuthContext = createContext();
let loginmsg = null;

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  // Check for token in local storage on mount
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decoded = jwtDecode(token);
      setUser(decoded);
    }
  }, []);

  // Login function to authenticate user and store token
  const login = async (username, password) => {
    try {
      const response = await axios.post('http://localhost:5000/login', { username, password });
      const { token } = response.data;
      localStorage.setItem('token', token);
      const decoded = jwtDecode(token);
      setUser(decoded);
      return true; // Indicate success
    } catch (error) {
      loginmsg = error;
      console.error('Login failed', error);
      return false; // Indicate failure
    }
  };

  // Logout function to clear token and reset user state
  const logout = () => {
    localStorage.removeItem('token');
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };