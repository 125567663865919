import React from 'react';
// import EventCalendar from '../components/EventCalendar';

const Calendar = () => {
    return <>
  

    <div class="px-40 flex flex-1 justify-center py-5">
    <div class="layout-content-container flex flex-col flex-1">
      <div class="flex flex-wrap justify-between gap-3 p-4">
        <div class="flex min-w-72 flex-col gap-3">
          <p class="text-[#1b140e] tracking-light text-[32px] font-bold leading-tight">Events Calendar</p>
          <p class="text-[#97704e] text-sm font-normal leading-normal">Find out what's happening at Moose Lodge 1717</p>
        </div>
      </div>
      <h3 class="text-[#1b140e] text-lg font-bold leading-tight tracking-[-0.015em] px-4 pb-2 pt-4">Filter Events</h3>
      <div class="flex gap-3 p-3 flex-wrap pr-4">
        <div class="flex h-8 shrink-0 items-center justify-center gap-x-2 rounded-full bg-[#f3ede7] pl-4 pr-4">
          <p class="text-[#1b140e] text-sm font-medium leading-normal">Entertainment</p>
        </div>
        <div class="flex h-8 shrink-0 items-center justify-center gap-x-2 rounded-full bg-[#f3ede7] pl-4 pr-4">
          <p class="text-[#1b140e] text-sm font-medium leading-normal">Meetings</p>
        </div>
        <div class="flex h-8 shrink-0 items-center justify-center gap-x-2 rounded-full bg-[#f3ede7] pl-4 pr-4">
          <p class="text-[#1b140e] text-sm font-medium leading-normal">Special Events</p>
        </div>
        <div class="flex h-8 shrink-0 items-center justify-center gap-x-2 rounded-full bg-[#f3ede7] pl-4 pr-4">
          <p class="text-[#1b140e] text-sm font-medium leading-normal">Youth Activities</p>
        </div>
        <div class="flex h-8 shrink-0 items-center justify-center gap-x-2 rounded-full bg-[#f3ede7] pl-4 pr-4">
          <p class="text-[#1b140e] text-sm font-medium leading-normal">Lodge Activities</p>
        </div>
      </div>
      <h3 class="text-[#1b140e] text-lg font-bold leading-tight tracking-[-0.015em] px-4 pb-2 pt-4">Upcoming Events</h3>


      <div class="flex flex-wrap items-center justify-center gap-6 p-4">
        <div class="flex min-w-72 max-w-[336px] flex-1 flex-col gap-0.5">
          <div class="flex items-center p-1 justify-between">
            <button>
              <div class="text-[#1b140e] flex size-10 items-center justify-center" data-icon="CaretLeft" data-size="18px" data-weight="regular">
                <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" fill="currentColor" viewBox="0 0 256 256">
                  <path d="M165.66,202.34a8,8,0,0,1-11.32,11.32l-80-80a8,8,0,0,1,0-11.32l80-80a8,8,0,0,1,11.32,11.32L91.31,128Z"></path>
                </svg>
              </div>
            </button>
            <p class="text-[#1b140e] text-base font-bold leading-tight flex-1 text-center">August 2023</p>
            <button>
              <div class="text-[#1b140e] flex size-10 items-center justify-center" data-icon="CaretRight" data-size="18px" data-weight="regular">
                <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" fill="currentColor" viewBox="0 0 256 256">
                  <path d="M181.66,133.66l-80,80a8,8,0,0,1-11.32-11.32L164.69,128,90.34,53.66a8,8,0,0,1,11.32-11.32l80,80A8,8,0,0,1,181.66,133.66Z"></path>
                </svg>
              </div>
            </button>
          </div>
          <div class="grid grid-cols-7">
            <p class="text-[#1b140e] text-[13px] font-bold leading-normal tracking-[0.015em] flex h-12 w-full items-center justify-center pb-0.5">S</p>
            <p class="text-[#1b140e] text-[13px] font-bold leading-normal tracking-[0.015em] flex h-12 w-full items-center justify-center pb-0.5">M</p>
            <p class="text-[#1b140e] text-[13px] font-bold leading-normal tracking-[0.015em] flex h-12 w-full items-center justify-center pb-0.5">T</p>
            <p class="text-[#1b140e] text-[13px] font-bold leading-normal tracking-[0.015em] flex h-12 w-full items-center justify-center pb-0.5">W</p>
            <p class="text-[#1b140e] text-[13px] font-bold leading-normal tracking-[0.015em] flex h-12 w-full items-center justify-center pb-0.5">T</p>
            <p class="text-[#1b140e] text-[13px] font-bold leading-normal tracking-[0.015em] flex h-12 w-full items-center justify-center pb-0.5">F</p>
            <p class="text-[#1b140e] text-[13px] font-bold leading-normal tracking-[0.015em] flex h-12 w-full items-center justify-center pb-0.5">S</p>
            <button class="h-12 w-full text-[#97704e] col-start-4 text-sm font-medium leading-normal">
              <div class="flex size-full items-center justify-center rounded-full">1</div>
            </button>
            <button class="h-12 w-full text-[#97704e] text-sm font-medium leading-normal">
              <div class="flex size-full items-center justify-center rounded-full">2</div>
            </button>
            <button class="h-12 w-full text-[#97704e] text-sm font-medium leading-normal">
              <div class="flex size-full items-center justify-center rounded-full">3</div>
            </button>
            <button class="h-12 w-full text-[#97704e] text-sm font-medium leading-normal">
              <div class="flex size-full items-center justify-center rounded-full">4</div>
            </button>
            <button class="h-12 w-full text-[#1b140e] text-sm font-medium leading-normal">
              <div class="flex size-full items-center justify-center rounded-full bg-[#e78027]">5</div>
            </button>
            <button class="h-12 w-full text-[#1b140e] text-sm font-medium leading-normal">
              <div class="flex size-full items-center justify-center rounded-full">6</div>
            </button>
            <button class="h-12 w-full text-[#1b140e] text-sm font-medium leading-normal">
              <div class="flex size-full items-center justify-center rounded-full">7</div>
            </button>
            <button class="h-12 w-full text-[#1b140e] text-sm font-medium leading-normal">
              <div class="flex size-full items-center justify-center rounded-full">8</div>
            </button>
            <button class="h-12 w-full text-[#1b140e] text-sm font-medium leading-normal">
              <div class="flex size-full items-center justify-center rounded-full">9</div>
            </button>
            <button class="h-12 w-full text-[#1b140e] text-sm font-medium leading-normal">
              <div class="flex size-full items-center justify-center rounded-full">10</div>
            </button>
            <button class="h-12 w-full text-[#1b140e] text-sm font-medium leading-normal">
              <div class="flex size-full items-center justify-center rounded-full">11</div>
            </button>
            <button class="h-12 w-full text-[#1b140e] text-sm font-medium leading-normal">
              <div class="flex size-full items-center justify-center rounded-full">12</div>
            </button>
            <button class="h-12 w-full text-[#1b140e] text-sm font-medium leading-normal">
              <div class="flex size-full items-center justify-center rounded-full">13</div>
            </button>
            <button class="h-12 w-full text-[#1b140e] text-sm font-medium leading-normal">
              <div class="flex size-full items-center justify-center rounded-full">14</div>
            </button>
            <button class="h-12 w-full text-[#1b140e] text-sm font-medium leading-normal">
              <div class="flex size-full items-center justify-center rounded-full">15</div>
            </button>
            <button class="h-12 w-full text-[#1b140e] text-sm font-medium leading-normal">
              <div class="flex size-full items-center justify-center rounded-full">16</div>
            </button>
            <button class="h-12 w-full text-[#1b140e] text-sm font-medium leading-normal">
              <div class="flex size-full items-center justify-center rounded-full">17</div>
            </button>
            <button class="h-12 w-full text-[#1b140e] text-sm font-medium leading-normal">
              <div class="flex size-full items-center justify-center rounded-full">18</div>
            </button>
            <button class="h-12 w-full text-[#1b140e] text-sm font-medium leading-normal">
              <div class="flex size-full items-center justify-center rounded-full">19</div>
            </button>
            <button class="h-12 w-full text-[#1b140e] text-sm font-medium leading-normal">
              <div class="flex size-full items-center justify-center rounded-full">20</div>
            </button>
            <button class="h-12 w-full text-[#1b140e] text-sm font-medium leading-normal">
              <div class="flex size-full items-center justify-center rounded-full">21</div>
            </button>
            <button class="h-12 w-full text-[#1b140e] text-sm font-medium leading-normal">
              <div class="flex size-full items-center justify-center rounded-full">22</div>
            </button>
            <button class="h-12 w-full text-[#1b140e] text-sm font-medium leading-normal">
              <div class="flex size-full items-center justify-center rounded-full">23</div>
            </button>
            <button class="h-12 w-full text-[#1b140e] text-sm font-medium leading-normal">
              <div class="flex size-full items-center justify-center rounded-full">24</div>
            </button>
            <button class="h-12 w-full text-[#1b140e] text-sm font-medium leading-normal">
              <div class="flex size-full items-center justify-center rounded-full">25</div>
            </button>
            <button class="h-12 w-full text-[#1b140e] text-sm font-medium leading-normal">
              <div class="flex size-full items-center justify-center rounded-full">26</div>
            </button>
            <button class="h-12 w-full text-[#1b140e] text-sm font-medium leading-normal">
              <div class="flex size-full items-center justify-center rounded-full">27</div>
            </button>
            <button class="h-12 w-full text-[#1b140e] text-sm font-medium leading-normal">
              <div class="flex size-full items-center justify-center rounded-full">28</div>
            </button>
            <button class="h-12 w-full text-[#1b140e] text-sm font-medium leading-normal">
              <div class="flex size-full items-center justify-center rounded-full">29</div>
            </button>
            <button class="h-12 w-full text-[#1b140e] text-sm font-medium leading-normal">
              <div class="flex size-full items-center justify-center rounded-full">30</div>
            </button>
          </div>
        </div>
      </div>
      <h3 class="text-[#1b140e] text-lg font-bold leading-tight tracking-[-0.015em] px-4 pb-2 pt-4">Saturday, August 26</h3>
      <div class="flex items-center gap-4 bg-[#fcfaf8] px-4 min-h-[72px] py-2 justify-between">
        <div class="flex flex-col justify-center">
          <p class="text-[#1b140e] text-base font-medium leading-normal line-clamp-1">Comedy Night</p>
          <p class="text-[#97704e] text-sm font-normal leading-normal line-clamp-2">Dinner: 5:30pm - 7:00pm, $15pp Show: 8:00pm - 10:00pm, $20pp</p>
        </div>
        <div class="shrink-0">
          <button
            class="flex min-w-[84px] max-w-[480px] cursor-pointer items-center justify-center overflow-hidden rounded-full h-8 px-4 bg-[#f3ede7] text-[#1b140e] text-sm font-medium leading-normal w-fit"
          >
            <span class="truncate">Buy Tickets</span>
          </button>
        </div>
      </div>
      <div class="flex items-center gap-4 bg-[#fcfaf8] px-4 min-h-[72px] py-2 justify-between">
        <div class="flex flex-col justify-center">
          <p class="text-[#1b140e] text-base font-medium leading-normal line-clamp-1">Karaoke Night</p>
          <p class="text-[#97704e] text-sm font-normal leading-normal line-clamp-2">6:00pm - 9:00pm</p>
        </div>
        <div class="shrink-0">
          <button
            class="flex min-w-[84px] max-w-[480px] cursor-pointer items-center justify-center overflow-hidden rounded-full h-8 px-4 bg-[#f3ede7] text-[#1b140e] text-sm font-medium leading-normal w-fit"
          >
            <span class="truncate">More Info</span>
          </button>
        </div>
      </div>
      <div class="flex items-center gap-4 bg-[#fcfaf8] px-4 min-h-[72px] py-2 justify-between">
        <div class="flex flex-col justify-center">
          <p class="text-[#1b140e] text-base font-medium leading-normal line-clamp-1">Bingo</p>
          <p class="text-[#97704e] text-sm font-normal leading-normal line-clamp-2">6:00pm - 8:00pm</p>
        </div>
        <div class="shrink-0">
          <button
            class="flex min-w-[84px] max-w-[480px] cursor-pointer items-center justify-center overflow-hidden rounded-full h-8 px-4 bg-[#f3ede7] text-[#1b140e] text-sm font-medium leading-normal w-fit"
          >
            <span class="truncate">More Info</span>
          </button>
        </div>
      </div>
    </div>
    </div>
    
    
    </>;
};

export default Calendar;
