import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Facilities from './pages/Facilities';
import History from './pages/History';
import Merch from './pages/Merch';
import Officers from './pages/Officers';
import Menu from './pages/Menu';
import Calendar from './pages/Calendar';
import YouTubeChannel from './components/YouTubeChannel';
import Dashboard from './adminpages/Dashboard';
import AdminLogin from './adminpages/AdminLogin';
import EventList from './adminpages/EventList';





const App = () => {
  return (
    <Router>
      <div>

        <Header />

          <div className="layout-content-container flex flex-col flex-1">

            <main>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/facilities" element={<Facilities />} />
                <Route path="/menu" element={<Menu />} />
                <Route path="/history" element={<History />} />
                <Route path="/merch" element={<Merch />} />
                <Route path="/officers" element={<Officers />} />
                <Route path="/calendar" element={<Calendar />} />
                <Route path="/youtube" element={<YouTubeChannel />} />

         
                <Route path="/adminlogin" element={<AdminLogin />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/eventlist" element={<EventList />} />


              </Routes>
            </main>

          </div>

        <Footer />
      </div>
    </Router>
  );
};

export default App;
