import React from 'react';

const Officers = () => {
  return (
    <>

    <center><img src="./img/spacemoose01.png" className="mt-20" width="400" /></center>    
    <div className="flex flex-1 justify-center">

      <div className="layout-content-container flex flex-col max-w-[960px] flex-1">
        <div className="flex flex-wrap justify-between gap-3 p-4">
          <p className="text-[#1b140e] tracking-light text-[32px] font-bold leading-tight min-w-72">
            Lodge Officers
          </p>
        </div>
        {officers.map((officer, index) => (
          <div key={index} className="flex gap-4 bg-[#fcfaf8] px-4 py-3 mb-4">
            <div
              className="bg-center bg-no-repeat aspect-square bg-cover rounded-full h-[70px] w-[70px]"
              style={{
                backgroundImage: `url(${officer.image})`,
              }}
            ></div>
            <div className="flex flex-1 flex-col justify-center">
              <p className="text-[#1b140e] text-base font-medium leading-normal">{officer.name}</p>
              <p className="text-[#97704e] text-sm font-normal leading-normal">{officer.position}</p>
              <p className="text-[#97704e] text-sm font-normal leading-normal">{officer.description}</p>
            </div>
          </div>
        ))}

<div className="flex gap-3 rounded-lg border border-[#e7dbd0] bg-[#fcfaf8] p-4 mt-4">
  <div className="flex items-center">
    <div className="text-[#1b140e] mr-3" data-icon="Trophy" data-size="24px" data-weight="regular">
      <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 256 256">
        <path
          d="M232,64H208V56a16,16,0,0,0-16-16H64A16,16,0,0,0,48,56v8H24A16,16,0,0,0,8,80V96a40,40,0,0,0,40,40h3.65A80.13,80.13,0,0,0,120,191.61V216H96a8,8,0,0,0,0,16h64a8,8,0,0,0,0-16H136V191.58c31.94-3.23,58.44-25.64,68.08-55.58H208a40,40,0,0,0,40-40V80A16,16,0,0,0,232,64ZM48,120A24,24,0,0,1,24,96V80H48v32q0,4,.39,8Zm144-8.9c0,35.52-28.49,64.64-63.51,64.9H128a64,64,0,0,1-64-64V56H192ZM232,96a24,24,0,0,1-24,24h-.5a81.81,81.81,0,0,0,.5-8.9V80h24Z"
        ></path>
      </svg>
    </div>
    <div className="flex flex-col gap-1">
      <h2 className="text-[#1b140e] text-base font-bold leading-tight">Leadership Development</h2>
      <p className="text-[#97704e] text-sm font-normal leading-normal">
        Develop valuable leadership skills by serving as an officer or committee member at your Lodge.
      </p>
    </div>
  </div>
</div>


      </div>
    </div>

    </>
  );
};

const officers = [
  {
    name: 'Matt Epps',
    position: 'President',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum vestibulum. Cras venenatis euismod malesuada.',
    image: 'https://cdn.usegalileo.ai/sdxl10/2947a690-8882-4a16-9253-06c0a080b412.png',
  },
  {
    name: 'Chris Pedone',
    position: 'Jr. Past President',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum vestibulum. Cras venenatis euismod malesuada.',
    image: 'https://cdn.usegalileo.ai/sdxl10/aceb4f22-c4ea-4a73-9ca8-dd3b65aeb36e.png',
  },
  {
    name: 'Matt MccNeely',
    position: 'Vice President',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum vestibulum. Cras venenatis euismod malesuada.',
    image: 'https://cdn.usegalileo.ai/sdxl10/aceb4f22-c4ea-4a73-9ca8-dd3b65aeb36e.png',
  },
  {
    name: 'Maggie Collins',
    position: 'Administrator',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum vestibulum. Cras venenatis euismod malesuada.',
    image: 'https://cdn.usegalileo.ai/sdxl10/8e0c91a3-6675-4742-8cd2-7bcf07a70bb4.png',
  },
  {
    name: 'Calvin Ivy',
    position: 'Treasurer',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum vestibulum. Cras venenatis euismod malesuada.',
    image: 'https://cdn.usegalileo.ai/sdxl10/8088b4cc-f49c-4a8f-916b-430c026a51fc.png',
  },
  {
    name: 'Steve Gainer',
    position: 'Chaplin',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum vestibulum. Cras venenatis euismod malesuada.',
    image: 'https://cdn.usegalileo.ai/sdxl10/9b3eb4ec-0e7b-4bc8-ac53-735b0fff7d28.png',
  },
  {
    name: 'Heather Epps',
    position: 'Trustee',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum vestibulum. Cras venenatis euismod malesuada.',
    image: 'https://cdn.usegalileo.ai/sdxl10/9b3eb4ec-0e7b-4bc8-ac53-735b0fff7d28.png',
  },
  {
    name: 'Shari McNeely',
    position: 'Trustee',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum vestibulum. Cras venenatis euismod malesuada.',
    image: 'https://cdn.usegalileo.ai/sdxl10/9b3eb4ec-0e7b-4bc8-ac53-735b0fff7d28.png',
  },
  {
    name: 'Rich Boyer',
    position: 'Trustee',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum vestibulum. Cras venenatis euismod malesuada.',
    image: 'https://cdn.usegalileo.ai/sdxl10/9b3eb4ec-0e7b-4bc8-ac53-735b0fff7d28.png',
  },
];

export default Officers;
