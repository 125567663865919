import React from 'react';
import BecomeMember from '../components/BecomeMember';
import LatestVideos from '../components/LatestVideos';
import Contact from './Contact';

const Home = () => {
  return <>


            <div className="@container">
              <div className="@[480px]:p-4 overflow-hidden">
                <div
                    className="overflow-visible relative flex min-h-[80vh] flex-col gap-6 bg-cover bg-center bg-no-repeat items-center justify-center p-4  "
                    style={{
                        background:  `linear-gradient(rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.4) 100%), url(${"img/space-bg.png"})`, backgroundPositionX: 'center', backgroundPositionY: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', 
                    }}
                >

                    <div className="p-20 flex flex-col gap-2 text-center z-10 absolute animate__animated  animate__backInDown animate__delay-2s">
                        <h1 className="text-shadow opacity-85 text-white text-4xl font-black leading-tight tracking-[-0.033em] @[480px]:text-5xl @[480px]:font-black @[480px]:leading-tight @[480px]:tracking-[-0.033em] ">
                        Welcome to Moose Lodge 1717
                        </h1>
                        <h2 className="text-shadow text-white text-sm font-normal leading-normal @[480px]:text-base @[480px]:font-normal @[480px]:leading-normal">We are a fraternal organization dedicated to helping our <br /> members living in the Space Coast community.</h2>
                    </div>


                    <div className="blur-sm origin-center block absolute top-0 bottom-0 left-0 right-0 w-vw h-vh  animate__animated  animate__zoomIn animate-spin animate-infinite animate-duration-[90000ms] animate-ease-in" style={{ background:  ` url(${"img/space-bg-overlay.png"})`, backgroundPositionX: 'center', backgroundPositionY: 'center', backgroundSize: '200%', backgroundRepeat: 'no-repeat', }}></div>             
                    <div className="absolute top-0 bottom-0 left-0 right-0 w-vw h-vh  animate-ease-in animate__animated  animate__zoomIn animate__slow"><div className="block absolute top-0 bottom-0 left-0 right-0 width-[100%]  animate-fade-down animate-wiggle animate-infinite animate-duration-[6000ms] bg-contain" style={{ background:  ` url(${"img/space-moose.png"})`, backgroundPositionX: 'center', backgroundPositionY: 'center',  backgroundRepeat: 'no-repeat', }}></div></div>
                  
                  </div>
              </div>
            </div>



            <div className="sm:px-1 md:px-20 lg:px-40 justify-center py-5">


                <h2 className="text-[#1b140e] text-[22px] font-bold leading-tight tracking-[-0.015em] px-4 pb-3 pt-5">Upcoming Events</h2>
                <div className="grid grid-cols-[repeat(auto-fit,minmax(158px,1fr))] gap-3 p-4">
                <div className="flex flex-col gap-3 pb-3">
                    <div
                    className="w-full bg-center bg-no-repeat aspect-video bg-cover rounded-xl"
                    style={{ backgroundImage: 'url("img/bingo-card.png")' }}
                    ></div>
                    <div>
                    <p className="text-[#1b140e] text-base font-medium leading-normal">Bingo Night</p>
                    <p className="text-[#97704e] text-sm font-normal leading-normal">Thursday, September 30th, 6:00 PM</p>
                    </div>
                </div>
                <div className="flex flex-col gap-3 pb-3">
                    <div
                    className="w-full bg-center bg-no-repeat aspect-video bg-cover rounded-xl"
                    style={{ backgroundImage: 'url("img/installation_2.png")' }}
                    ></div>
                    <div>
                    <p className="text-[#1b140e] text-base font-medium leading-normal">Installation of Officers</p>
                    <p className="text-[#97704e] text-sm font-normal leading-normal">Saturday, October 9th, 5:00 PM</p>
                    </div>
                </div>
                <div className="flex flex-col gap-3 pb-3">
                    <div
                    className="w-full bg-center bg-no-repeat aspect-video bg-cover rounded-xl"
                    style={{ backgroundImage: 'url("img/steak.jpeg")' }}
                    ></div>
                    <div>
                    <p className="text-[#1b140e] text-base font-medium leading-normal">Steak Night</p>
                    <p className="text-[#97704e] text-sm font-normal leading-normal">Thursday, October 14th, 6:00 PM</p>
                    </div>
                </div>
                </div>
            

                    <BecomeMember />

                    <LatestVideos />
            </div>
            <div className="bg-[#f3ede7]">
            <div className="sm:px-1 md:px-20 lg:px-40 justify-center py-5"><Contact /></div>
            </div>
  </>;
};

export default Home;
