


import React, { useState, useContext } from 'react';
import { AuthProvider , AuthContext } from './../components/AuthContext'
import { NavLink } from 'react-router-dom';

const AdminNav = () => {

    const { user, logout } = useContext(AuthContext);
    return (
 <>
              <div class="flex flex-col gap-4">
              <div class="flex flex-col gap-2">
                <div class="flex items-center gap-3 px-3 py-2">
                  <div class="text-[#0e141b]" data-icon="HouseSimple" data-size="24px" data-weight="regular">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 256 256">
                      <path
                        d="M218.83,103.77l-80-75.48a1.14,1.14,0,0,1-.11-.11,16,16,0,0,0-21.53,0l-.11.11L37.17,103.77A16,16,0,0,0,32,115.55V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V115.55A16,16,0,0,0,218.83,103.77ZM208,208H48V115.55l.11-.1L128,40l79.9,75.43.11.1Z"
                      ></path>
                    </svg>
                  </div>
                  <p class="text-[#0e141b] text-sm font-medium leading-normal">Dashboard</p>
                </div>
                <div class="flex items-center gap-3 px-3 py-2 rounded-xl bg-[#e7edf3]">
                  <div class="text-[#0e141b]" data-icon="CurrencyDollarSimple" data-size="24px" data-weight="fill">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 256 256">
                      <path
                        d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm20,168H136v8a8,8,0,0,1-16,0v-8h-8a36,36,0,0,1-36-36,8,8,0,0,1,16,0,20,20,0,0,0,20,20h36a20,20,0,0,0,0-40H116a36,36,0,0,1,0-72h4V56a8,8,0,0,1,16,0v8h4a36,36,0,0,1,36,36,8,8,0,0,1-16,0,20,20,0,0,0-20-20H116a20,20,0,0,0,0,40h32a36,36,0,0,1,0,72Z"
                      ></path>
                    </svg>
                  </div>

                  <NavLink 
            to="/eventlist" 
            exact 
            className="text-[#0e141b] text-sm font-medium leading-normal" 
          >
            Events
            </NavLink>

                </div>
                <div class="flex items-center gap-3 px-3 py-2">
                  <div class="text-[#0e141b]" data-icon="TagSimple" data-size="24px" data-weight="regular">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 256 256">
                      <path
                        d="M246.66,123.56,201,55.13A15.94,15.94,0,0,0,187.72,48H40A16,16,0,0,0,24,64V192a16,16,0,0,0,16,16H187.72A16,16,0,0,0,201,200.88l45.63-68.44A8,8,0,0,0,246.66,123.56ZM187.72,192H40V64H187.72l42.66,64Z"
                      ></path>
                    </svg>
                  </div>
                  <p class="text-[#0e141b] text-sm font-medium leading-normal">Users</p>
                </div>
                <div class="flex items-center gap-3 px-3 py-2">
                  <div class="text-[#0e141b]" data-icon="ChartPie" data-size="24px" data-weight="regular">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 256 256">
                      <path
                        d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm71.87,53.27L136,114.14V40.37A88,88,0,0,1,199.87,77.27ZM120,40.37v83l-71.89,41.5A88,88,0,0,1,120,40.37ZM128,216a88,88,0,0,1-71.87-37.27L207.89,91.12A88,88,0,0,1,128,216Z"
                      ></path>
                    </svg>
                  </div>
                  <p class="text-[#0e141b] text-sm font-medium leading-normal">Reports</p>
                </div>
                <div class="flex items-center gap-3 px-3 py-2">
                  <div class="text-[#0e141b]" data-icon="PiggyBank" data-size="24px" data-weight="regular">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 256 256">
                      <path
                        d="M192,116a12,12,0,1,1-12-12A12,12,0,0,1,192,116ZM152,64H112a8,8,0,0,0,0,16h40a8,8,0,0,0,0-16Zm96,48v32a24,24,0,0,1-24,24h-2.36l-16.21,45.38A16,16,0,0,1,190.36,224H177.64a16,16,0,0,1-15.07-10.62L160.65,208h-57.3l-1.92,5.38A16,16,0,0,1,86.36,224H73.64a16,16,0,0,1-15.07-10.62L46,178.22a87.69,87.69,0,0,1-21.44-48.38A16,16,0,0,0,16,144a8,8,0,0,1-16,0,32,32,0,0,1,24.28-31A88.12,88.12,0,0,1,112,32H216a8,8,0,0,1,0,16H194.61a87.93,87.93,0,0,1,30.17,37c.43,1,.85,2,1.25,3A24,24,0,0,1,248,112Zm-16,0a8,8,0,0,0-8-8h-3.66a8,8,0,0,1-7.64-5.6A71.9,71.9,0,0,0,144,48H112A72,72,0,0,0,58.91,168.64a8,8,0,0,1,1.64,2.71L73.64,208H86.36l3.82-10.69A8,8,0,0,1,97.71,192h68.58a8,8,0,0,1,7.53,5.31L177.64,208h12.72l18.11-50.69A8,8,0,0,1,216,152h8a8,8,0,0,0,8-8Z"
                      ></path>
                    </svg>
                  </div>
                  <p class="text-[#0e141b] text-sm font-medium leading-normal">Blog</p>
                </div>
                <div class="flex items-center gap-3 px-3 py-2">
                  <div class="text-[#0e141b]" data-icon="Flag" data-size="24px" data-weight="regular">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 256 256">
                      <path
                        d="M34.76,42A8,8,0,0,0,32,48V216a8,8,0,0,0,16,0V171.77c26.79-21.16,49.87-9.75,76.45,3.41,16.4,8.11,34.06,16.85,53,16.85,13.93,0,28.54-4.75,43.82-18a8,8,0,0,0,2.76-6V48A8,8,0,0,0,210.76,42c-28,24.23-51.72,12.49-79.21-1.12C103.07,26.76,70.78,10.79,34.76,42ZM208,164.25c-26.79,21.16-49.87,9.74-76.45-3.41-25-12.35-52.81-26.13-83.55-8.4V51.79c26.79-21.16,49.87-9.75,76.45,3.4,25,12.35,52.82,26.13,83.55,8.4Z"
                      ></path>
                    </svg>
                  </div>
                  <p  onClick={logout} class="text-[#0e141b] text-sm font-medium leading-normal">Logout</p>
                </div>
              </div>
            </div>
            </>
    );
};

export default AdminNav;
