import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { xml2json } from 'xml-js';
import Modal from 'react-modal';

Modal.setAppElement('#root'); // Replace '#root' with the id of your main app element if it's different

const LatestVideos = () => {
  const [videos, setVideos] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const formData = new FormData();
        formData.append('url', 'https://www.youtube.com/feeds/videos.xml?channel_id=UCyZIB6MC6ZmdgO4Elze5ewA');

        const response = await axios.post('https://www.wilsonstudioweb.com/proxy.cfc?method=get', formData, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        });

        const json = JSON.parse(xml2json(response.data, { compact: true }));
        const entries = json.feed.entry;

        if (entries) {
          const videoItems = entries.slice(0, 4).map((entry) => ({
            id: entry['yt:videoId']._text,
            title: entry.title._text,
            thumbnail: entry['media:group']['media:thumbnail']._attributes.url,
            published: entry.published._text,
          }));
          setVideos(videoItems);
        } else {
          console.error('No videos found');
        }
      } catch (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.error('Error response:', error.response);
          setError(`Error: ${error.response.status} - ${error.response.data}`);
        } else if (error.request) {
          // The request was made but no response was received
          console.error('Error request:', error.request);
          setError('Error: No response received from the server.');
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error('Error message:', error.message);
          setError(`Error: ${error.message}`);
        }
      }
    };

    fetchVideos();
  }, []);

  const openModal = (video) => {
    setSelectedVideo(video);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedVideo(null);
    setModalIsOpen(false);
  };

  return (
    <div className="container mx-auto py-8">
      <h2 className="text-xl font-bold">Moose International Media</h2>
      <div className="text-sm mb-4">
        Check out the latest videos from Moose International’s official YouTube channel for the most current information.
      </div>
      {error ? (
        <div className="text-red-500">{error}</div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
          {videos.map((video) => (
            <div
              key={video.id}
              className="bg-white rounded-lg cursor-pointer text-gray-500 hover:text-[#003366]"
              onClick={() => openModal(video)}
            >
              <img className="w-full h-auto rounded-md" src={video.thumbnail} alt={video.title} />
              <p className="mt-2 text-xs font-medium">{video.title}</p>
              <p className="mt-2 text-xs font-medium">{new Date(video.published).toLocaleDateString()}</p>
            </div>
          ))}
          <Link to="/youtube">
            <button className="flex min-w-[84px] max-w-[480px] cursor-pointer items-center justify-center overflow-hidden rounded-full h-8 px-4 bg-[#003366] text-[#fff] text-sm font-medium leading-normal w-fit">
              <span className="truncate">More Videos</span>
            </button>
          </Link>
        </div>
      )}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Video Modal"
        className="modal"
        overlayClassName="overlay outline-none"
      >
        {selectedVideo && (
          <div className="h-full">
            <div className="relative rounded overflow-hidden outline-none p-10 h-full">
              <iframe
                src={`https://www.youtube.com/embed/${selectedVideo.id}`}
                title={selectedVideo.title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                className="video outline-none"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default LatestVideos;
