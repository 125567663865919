import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthProvider , AuthContext } from './../components/AuthContext'

import axios from 'axios';
import AdminNav from '../components/AdminNav';


const Dashboard = () => {
    const { user, logout } = useContext(AuthContext);
    const navigate = useNavigate();
    const [showEvents, setShowEvents] = useState(true);
    const [events, setEvents] = useState([]);
    const [categories, setCategories] = useState([]);
  
    useEffect(() => {
      if (!user) {
        navigate('/adminlogin');
      }
    }, [user, navigate]);
  
    const fetchEvents = () => {
      const token = localStorage.getItem('token');
      axios.get('http://localhost:5000/events', {
        headers: { Authorization: token }
      })
        .then(response => {
          setEvents(response.data);
        })
        .catch(error => {
          console.error('There was an error fetching the events!', error);
        });
    };
  
    const fetchCategories = () => {
      const token = localStorage.getItem('token');
      axios.get('http://localhost:5000/eventCategory', {
        headers: { Authorization: token }
      })
        .then(response => {
          setCategories(response.data);
        })
        .catch(error => {
          console.error('There was an error fetching the categories!', error);
        });
    };
  
    useEffect(() => {
      fetchEvents();
      fetchCategories();
    }, []);
  
    return (

<>


<div class="flex  w-full">
   <div class="bg-gray-200 p-4  items-start">
      <AdminNav />
   </div>

  
   <div class="bg-gray-100 w-full">

        <h1 className="text-2xl font-bold mb-4">Dashboard</h1>

   </div>


</div>




      </>
    );
  };
  

export default Dashboard;
