import React from 'react';

const BecomeMember = () => {
  return <>


<h2 className="text-[#1b140e] text-[22px] font-bold leading-tight tracking-[-0.015em] px-4 pb-3 pt-5">Become a Member</h2>
<div className="grid grid-cols-[repeat(auto-fit,minmax(158px,1fr))] gap-3 p-4">
  <div className="flex flex-1 gap-3 rounded-lg border border-[#e7dbd0] bg-[#fcfaf8] p-4 flex-col">
    <div className="text-[#1b140e]" data-icon="Users" data-size="24px" data-weight="regular">
      <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 256 256">
        <path
          d="M117.25,157.92a60,60,0,1,0-66.5,0A95.83,95.83,0,0,0,3.53,195.63a8,8,0,1,0,13.4,8.74,80,80,0,0,1,134.14,0,8,8,0,0,0,13.4-8.74A95.83,95.83,0,0,0,117.25,157.92ZM40,108a44,44,0,1,1,44,44A44.05,44.05,0,0,1,40,108Zm210.14,98.7a8,8,0,0,1-11.07-2.33A79.83,79.83,0,0,0,172,168a8,8,0,0,1,0-16,44,44,0,1,0-16.34-84.87,8,8,0,1,1-5.94-14.85,60,60,0,0,1,55.53,105.64,95.83,95.83,0,0,1,47.22,37.71A8,8,0,0,1,250.14,206.7Z"
        ></path>
      </svg>
    </div>
    <div className="flex flex-col gap-1">
      <h2 className="text-[#1b140e] text-base font-bold leading-tight">Fellowship</h2>
      <p className="text-[#97704e] text-sm font-normal leading-normal">Enjoy camaraderie with other Moose members through a variety of social events and activities.</p>
    </div>
  </div>
  <div className="flex flex-1 gap-3 rounded-lg border border-[#e7dbd0] bg-[#fcfaf8] p-4 flex-col">
    <div className="text-[#1b140e]" data-icon="Heart" data-size="24px" data-weight="regular">
      <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 256 256">
        <path
          d="M178,32c-20.65,0-38.73,8.88-50,23.89C116.73,40.88,98.65,32,78,32A62.07,62.07,0,0,0,16,94c0,70,103.79,126.66,108.21,129a8,8,0,0,0,7.58,0C136.21,220.66,240,164,240,94A62.07,62.07,0,0,0,178,32ZM128,206.8C109.74,196.16,32,147.69,32,94A46.06,46.06,0,0,1,78,48c19.45,0,35.78,10.36,42.6,27a8,8,0,0,0,14.8,0c6.82-16.67,23.15-27,42.6-27a46.06,46.06,0,0,1,46,46C224,147.61,146.24,196.15,128,206.8Z"
        ></path>
      </svg>
    </div>
    <div className="flex flex-col gap-1">
      <h2 className="text-[#1b140e] text-base font-bold leading-tight">Community Service</h2>
      <p className="text-[#97704e] text-sm font-normal leading-normal">
        Help make a difference for children and seniors in your community through Lodge-sponsored programs.
      </p>
    </div>
  </div>
  <div className="flex flex-1 gap-3 rounded-lg border border-[#e7dbd0] bg-[#fcfaf8] p-4 flex-col">
    <div className="text-[#1b140e]" data-icon="Gift" data-size="24px" data-weight="regular">
      <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 256 256">
        <path
          d="M216,72H180.92c.39-.33.79-.65,1.17-1A29.53,29.53,0,0,0,192,49.57,32.62,32.62,0,0,0,158.44,16,29.53,29.53,0,0,0,137,25.91a54.94,54.94,0,0,0-9,14.48,54.94,54.94,0,0,0-9-14.48A29.53,29.53,0,0,0,97.56,16,32.62,32.62,0,0,0,64,49.57,29.53,29.53,0,0,0,73.91,71c.38.33.78.65,1.17,1H40A16,16,0,0,0,24,88v32a16,16,0,0,0,16,16v64a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V136a16,16,0,0,0,16-16V88A16,16,0,0,0,216,72ZM149,36.51a13.69,13.69,0,0,1,10-4.5h.49A16.62,16.62,0,0,1,176,49.08a13.69,13.69,0,0,1-4.5,10c-9.49,8.4-25.24,11.36-35,12.4C137.7,60.89,141,45.5,149,36.51Zm-64.09.36A16.63,16.63,0,0,1,96.59,32h.49a13.69,13.69,0,0,1,10,4.5c8.39,9.48,11.35,25.2,12.39,34.92-9.72-1-25.44-4-34.92-12.39a13.69,13.69,0,0,1-4.5-10A16.6,16.6,0,0,1,84.87,36.87ZM40,88h80v32H40Zm16,48h64v64H56Zm144,64H136V136h64Zm16-80H136V88h80v32Z"
        ></path>
      </svg>
    </div>
    <div className="flex flex-col gap-1">
      <h2 className="text-[#1b140e] text-base font-bold leading-tight">Family Events</h2>
      <p className="text-[#97704e] text-sm font-normal leading-normal">
        Participate in family-oriented activities, events and programs designed to strengthen your family ties.
      </p>
    </div>
  </div>
  <div className="flex flex-1 gap-3 rounded-lg border border-[#e7dbd0] bg-[#fcfaf8] p-4 flex-col">
    <div className="text-[#1b140e]" data-icon="Handshake" data-size="24px" data-weight="regular">
      <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 256 256">
        <path
          d="M119.76,217.94A8,8,0,0,1,112,224a8.13,8.13,0,0,1-2-.24l-32-8a8,8,0,0,1-2.5-1.11l-24-16a8,8,0,1,1,8.88-13.31l22.84,15.23,30.66,7.67A8,8,0,0,1,119.76,217.94Zm132.69-96.46a15.89,15.89,0,0,1-8,9.25l-23.68,11.84-55.08,55.09a8,8,0,0,1-7.6,2.1l-64-16a8.06,8.06,0,0,1-2.71-1.25L35.86,142.87,11.58,130.73a16,16,0,0,1-7.16-21.46L29.27,59.58h0a16,16,0,0,1,21.46-7.16l22.06,11,53-15.14a8,8,0,0,1,4.4,0l53,15.14,22.06-11a16,16,0,0,1,21.46,7.16l24.85,49.69A15.9,15.9,0,0,1,252.45,121.48Zm-46.18,12.94L179.06,80H147.24L104,122c12.66,8.09,32.51,10.32,50.32-7.63a8,8,0,0,1,10.68-.61l34.41,27.57Zm-187.54-18,17.69,8.85L61.27,75.58,43.58,66.73ZM188,152.66l-27.71-22.19c-19.54,16-44.35,18.11-64.91,5a16,16,0,0,1-2.72-24.82.6.6,0,0,1,.08-.08L137.6,67.06,128,64.32,77.58,78.73,50.21,133.46l49.2,35.15,58.14,14.53Zm49.24-36.24L212.42,66.73l-17.69,8.85,24.85,49.69Z"
        ></path>
      </svg>
    </div>
    <div className="flex flex-col gap-1">
      <h2 className="text-[#1b140e] text-base font-bold leading-tight">Member Benefits</h2>
      <p className="text-[#97704e] text-sm font-normal leading-normal">
        Save money on everyday purchases with exclusive member discounts on travel, entertainment, and more.
      </p>
    </div>
  </div>


  <div className="flex flex-1 gap-3 rounded-lg border border-[#e7dbd0] bg-[#fcfaf8] p-4 flex-col">
    <div className="text-[#1b140e]" data-icon="Trophy" data-size="24px" data-weight="regular">
      <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 256 256">
        <path
          d="M232,64H208V56a16,16,0,0,0-16-16H64A16,16,0,0,0,48,56v8H24A16,16,0,0,0,8,80V96a40,40,0,0,0,40,40h3.65A80.13,80.13,0,0,0,120,191.61V216H96a8,8,0,0,0,0,16h64a8,8,0,0,0,0-16H136V191.58c31.94-3.23,58.44-25.64,68.08-55.58H208a40,40,0,0,0,40-40V80A16,16,0,0,0,232,64ZM48,120A24,24,0,0,1,24,96V80H48v32q0,4,.39,8Zm144-8.9c0,35.52-28.49,64.64-63.51,64.9H128a64,64,0,0,1-64-64V56H192ZM232,96a24,24,0,0,1-24,24h-.5a81.81,81.81,0,0,0,.5-8.9V80h24Z"
        ></path>
      </svg>
    </div>
    <div className="flex flex-col gap-1">
      <h2 className="text-[#1b140e] text-base font-bold leading-tight">Leadership Development</h2>
      <p className="text-[#97704e] text-sm font-normal leading-normal">Develop valuable leadership skills by serving as an officer or committee member at your Lodge.</p>
    </div>
  </div>
</div>


<h2 className="text-[#1b140e] text-[22px] font-bold leading-tight tracking-[-0.015em] px-4 pb-3 pt-5">How to join</h2>
<div className="flex gap-4 bg-[#fcfaf8] px-4 py-3 justify-between">
  <div className="flex items-start gap-4">
    <div
      className="text-[#1b140e] flex items-center justify-center rounded-lg bg-[#f3ede7] shrink-0 size-12"
      data-icon="MagnifyingGlass"
      data-size="24px"
      data-weight="regular"
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 256 256">
        <path
          d="M229.66,218.34l-50.07-50.06a88.11,88.11,0,1,0-11.31,11.31l50.06,50.07a8,8,0,0,0,11.32-11.32ZM40,112a72,72,0,1,1,72,72A72.08,72.08,0,0,1,40,112Z"
        ></path>
      </svg>
    </div>
    <div className="flex flex-1 flex-col justify-center">
      <p className="text-[#1b140e] text-base font-medium leading-normal">Find a Member</p>
      <p className="text-[#97704e] text-sm font-normal leading-normal">If you don't know a Moose member, we'll help you find a local Lodge to visit.</p>
      <p className="text-[#97704e] text-sm font-normal leading-normal">
        Membership is open to men ages 21 and older who believe in a Supreme Being and to women ages 18 and older who are related to a Moose member in good standing or who
        are eligible to join the Women of the Moose.
      </p>
    </div>
  </div>
  <div className="shrink-0">
    <button
      className="flex min-w-[84px] max-w-[480px] cursor-pointer items-center justify-center overflow-hidden rounded-full h-8 px-4 bg-[#003366] text-[#fff] text-sm font-medium leading-normal w-fit"
    >
      <span className="truncate">Find a Moose</span>
    </button>
  </div>
</div>
<div className="flex gap-4 bg-[#fcfaf8] px-4 py-3 justify-between">
  <div className="flex items-start gap-4">
    <div
      className="text-[#1b140e] flex items-center justify-center rounded-lg bg-[#f3ede7] shrink-0 size-12"
      data-icon="FileArrowUp"
      data-size="24px"
      data-weight="regular"
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 256 256">
        <path
          d="M213.66,82.34l-56-56A8,8,0,0,0,152,24H56A16,16,0,0,0,40,40V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V88A8,8,0,0,0,213.66,82.34ZM160,51.31,188.69,80H160ZM200,216H56V40h88V88a8,8,0,0,0,8,8h48V216Zm-42.34-77.66a8,8,0,0,1-11.32,11.32L136,139.31V184a8,8,0,0,1-16,0V139.31l-10.34,10.35a8,8,0,0,1-11.32-11.32l24-24a8,8,0,0,1,11.32,0Z"
        ></path>
      </svg>
    </div>
    <div className="flex flex-1 flex-col justify-center">
      <p className="text-[#1b140e] text-base font-medium leading-normal">Submit an Application</p>
      <p className="text-[#97704e] text-sm font-normal leading-normal">
        If you're ready to join, fill out an application and submit it to your local Lodge. Once your application is approved, you'll become a member of the Moose
        International and receive your membership card and a welcome kit.
      </p>
    </div>
  </div>
  <div className="shrink-0">
    <button
      className="flex min-w-[84px] max-w-[480px] cursor-pointer items-center justify-center overflow-hidden rounded-full h-8 px-4 bg-[#003366] text-[#fff] text-sm font-medium leading-normal w-fit"
    >
      <span className="truncate ">Apply Now</span>
    </button>
  </div>
</div>
</>;
};

export default BecomeMember;
