import React, { useEffect, useState } from 'react';
import axios from 'axios';
import EventForm from './EventForm';
import EventCategoryForm from './EventCategoryForm';
import EventCategoryList from './EventCategoryList';
import AdminNav from '../components/AdminNav';
import Modal from '../components/Modal';


const EventList = ({ fetchEvents }) => {
  const [events, setEvents] = useState([]);
  const [showEvents, setShowEvents] = useState(true);
  const [categories, setCategories] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const openModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  // Fetch events from API
  const fetchEventsFromAPI = () => {
    const token = localStorage.getItem('token');
    axios.get('http://localhost:5000/events', {
      headers: { Authorization: token }
    })
      .then(response => {
        setEvents(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the events!', error);
      });
  };

  // Use the provided fetchEvents function or the local one
  const fetchEventsToUse = fetchEventsFromAPI;


  const fetchCategories = () => {
    const token = localStorage.getItem('token');
    axios.get('http://localhost:5000/eventCategory', {
      headers: { Authorization: token }
    })
      .then(response => {
        setCategories(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the categories!', error);
      });
  };

  useEffect(() => {
    fetchCategories();
  }, [fetchEventsToUse]);


  return (

    <div class="flex  w-full">
      <div class="bg-gray-200 p-4  items-start">
          <AdminNav />
      </div>

    
      <div class="bg-gray-100 w-full p-10">
        <button  onClick={openModal} className={`mr-2 ${showEvents ? 'bg-blue-500' : 'bg-gray-500'} text-white text-xs font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline`}  >Manage Events</button>
        
  

        <Modal isVisible={isModalVisible} onClose={closeModal}>
          <EventForm fetchEvents={fetchEventsToUse} />
        </Modal>
    
        
        <button onClick={() => setShowEvents(false)} className={`${!showEvents ? 'bg-blue-500' : 'bg-gray-500'} text-white text-xs font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline`} >Manage Categories</button>

        <h2 className="text-2xl font-bold mb-4">Events List</h2>
        <ul>
          {events.map((event) => (
            <li key={event.EventID} className="mb-2 p-2 border rounded">
              <h3 className="text-xl font-bold">{event.Title}</h3>
              <p>{event.Description}</p>
              <p>Category ID: {event.CategoryID}</p>
              <p>Lodge ID: {event.LodgeID}</p>
              <p>Date: {event.EventDate}</p>
              <p>Time: {event.StartTime} - {event.EndTime}</p>
              <p>Recurring: {event.IsRecurring ? 'Yes' : 'No'}</p>
            </li>
          ))}
        </ul>
      </div>
  </div>
  );
};

export default EventList;