import React from 'react';

const Contact = () => {
  return (

      <div className="layout-content-container flex flex-col  flex-1 mt-[20px]">
        <div className="flex flex-wrap justify-between gap-3 p-4 pl-0">
          <p className="text-[#1b140e] tracking-light text-xl font-bold leading-tight min-w-72 pl-0">Contact Us</p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <h3 className="text-[#1b140e] text-base font-bold leading-tight tracking-[-0.015em] pb-2 pt-4">Address</h3>
            <p className="text-[#97704e] text-base font-normal leading-normal pb-3 pt-1">Moose Lodge 1717, 221 Clearlake Rd, Cocoa, FL 32922</p>
            <h3 className="text-[#1b140e]  text-base font-bold leading-tight tracking-[-0.015em] pb-2 pt-4">Phone</h3>
            <p className="text-[#97704e] text-base font-normal leading-normal pb-3 pt-1"><a href="+1-321-636-6975">(321) 636-6975</a></p>
            <h3 className="text-[#1b140e]  text-base font-bold leading-tight tracking-[-0.015em] pb-2 pt-4">Contact Form</h3>
            <div className="flex flex-wrap items-end gap-4 py-3">
              <label className="flex flex-col w-full">
                <input
                  placeholder="Name"
                  className="form-input w-full resize-none overflow-hidden rounded-xl text-[#1b140e] focus:outline-0 focus:ring-0 border border-[#e7dbd0] bg-[#fcfaf8] focus:border-[#e7dbd0] h-14 placeholder:text-[#97704e] p-[15px] text-base font-normal leading-normal"
                />
              </label>
            </div>
            <div className="flex flex-wrap items-end gap-4 py-3">
              <label className="flex flex-col w-full">
                <input
                  placeholder="Email"
                  className="form-input w-full resize-none overflow-hidden rounded-xl text-[#1b140e] focus:outline-0 focus:ring-0 border border-[#e7dbd0] bg-[#fcfaf8] focus:border-[#e7dbd0] h-14 placeholder:text-[#97704e] p-[15px] text-base font-normal leading-normal"
                />
              </label>
            </div>
            <div className="flex flex-wrap items-end gap-4 py-3">
              <label className="flex flex-col w-full">
                <textarea
                  placeholder="Message"
                  className="form-input w-full resize-none overflow-hidden rounded-xl text-[#1b140e] focus:outline-0 focus:ring-0 border border-[#e7dbd0] bg-[#fcfaf8] focus:border-[#e7dbd0] min-h-36 placeholder:text-[#97704e] p-[15px] text-base font-normal leading-normal"
                ></textarea>
              </label>
            </div>
            <div className="flex justify-end py-3">
              <button
                className="min-w-[84px] cursor-pointer items-center justify-center overflow-hidden rounded-full h-12 px-5 bg-[#e78027] text-[#1b140e] text-base font-bold leading-normal tracking-[0.015em]"
              >
                <span className="truncate">Send</span>
              </button>
            </div>
          </div>
          <div>
            <h3 className="text-[#1b140e] text-lg font-bold leading-tight tracking-[-0.015em] pb-2 pt-4">Location</h3>
            <div className="py-3">
              <iframe
                width="100%"
                height="450"
                frameBorder="0"
                scrolling="no"
                marginHeight="0"
                marginWidth="0"
                src="https://www.openstreetmap.org/export/embed.html?bbox=-80.755389%2C28.3569616%2C-80.755389%2C28.3569616&layer=mapnik&marker=28.3569616%2C-80.755389"
                style={{ border: '1px solid black' }}
              ></iframe>
              <br />
              <small>
                <a href="https://www.openstreetmap.org/?mlat=28.3569616&mlon=--80.755389#map=19/28.3569616/-80.755389" target="_new" className="text-[#97704e] text-sm">
                  View Larger Map
                </a>
              </small>
            </div>
          </div>
        </div>
      </div>

  );
};

export default Contact;
