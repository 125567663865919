import React from 'react';

const History = () => {


    const timeline = [
        {
            "title": "Founding of the Loyal Order of Moose",
            "description": "In 1890, Dr. John Henry Wilson, a Louisville physician, established the first Loyal Order of Moose lodge in the parlor of his home. The initial purpose was to create a series of men's social clubs. By the early 1890s, additional lodges were established in Cincinnati, St. Louis, and Indiana towns of Crawfordsville and Frankfort. However, Dr. Wilson himself became dissatisfied and departed from the infant organization before the turn of the century.",
            "date": "1890"
        },
        {
            "title": "Revival of the Loyal Order of Moose",
            "description": "It was on James J. Davis’ 33rd birthday, October 27, that he became just the 247th member of the Loyal Order of Moose. It was just the two remaining Indiana Lodges that kept the Moose from disappearing altogether, until the fall of 1906, when an outgoing young government clerk from Elwood, IN, was invited to enroll into the Crawfordsville Lodge.",
            "date": "1906"
        },
        {
            "title": "Expansion and Leadership Under James J. Davis",
            "description": "Davis’ marketing instincts were on-target: By 1912, the order had grown from 247 members in two Lodges, to a colossus of nearly 500,000 in more than 1,000 lodges. Davis, appointed the organization’s first chief executive with the new title of Director General, realized it was time to make good on the promise. The Moose began a program of paying “sick benefits” to members too ill to work-and, more ambitiously, Davis and the organization’s other officers made plans for a “Moose Institute,” to be centrally located somewhere in the Midwest that would provide a home, schooling a vocational training to children of deceased Moose Members.",
            "date": "1912"
        },
        {
            "title": "Watertown Lodge No. 830, Loyal Order of Moose",
            "description": "Watertown Lodge No. 830, Loyal Order of Moose, which was instituted on August 17, 1920 and which received its charter on Sept. 1 of that year, will hold an open house for the public at its new clubhouse, the former Watertown Post Office building in North Second Street on Sunday afternoon, June 23 from 1 to 4 p.m., according to announcement made today by officers of the lodge.  The public is invited to drop in and inspect the new quarters which give the lodge and its auxiliary a beautiful new home.",
            "date": "1920"
        },
        {
            "title": "Jim Davis' Influence and Political Career",
            "description": "As the Moose fraternity grew in visibility and influence, so did Jim Davis. President Warren Harding named him to his Cabinet as Secretary of Labor in 1921, and Davis continued in that post under Presidents Calvin Coolidge and Herbert Hoover as well. In November 1930, Davis a Republican, won election to the U.S. Senate from Pennsylvania, and he served there with distinction for the next 14 years. As both Labor Secretary and Senator, Davis was known as a conservative champion of labor, who fought hard for the rights of unions – but felt that the workingman should expect no “handouts” of any sort. In the Senate, it was Davis who spearheaded passage of landmark legislation to force building contractors to pay laborers “prevailing” union-level wages in any government construction work. The law bore his name: the Davis-Bacon Act.",
            "date": "1930"
        },



        {
            "title": "The Cocoa, Florida - Loyal Order of Moose chapter is formed",
            "description": "",
            "date": "1953"
        },

        {
            "title": "Women's Role and Growth in the Moose Organization",
            "description": "When the 19th Amendment granted women the right to vote in 1920, Smith, (from Indianapolis,) reasoned correctly, that women in politics would be a “growth market.” She quit her secretarial job to go to work in Warren Harding’s successful Presidential campaign – and still in her 20’s, she was rewarded with an appointment as Director of Public Employment in Washington. Labor Secretary Davis was her boss, and he immediately recognized her talent and drive. It took him five years to convince her to quit her government job and go to work for him running the Women of the Moose. A stereotypical “women’s program” held no interest for her, Smith argued. :So get out there and make a program,” Davis retorted. She did exactly that, as the organization’s first Grand Chancellor, for the next 38 years until her retirement in 1964, at which point the Women of the Moose boasted 250,000 members. It has since grown to more than 540,000 in approximately 1,600 Chapters.",
            "date": "1964"
        },
        {
            "title":  "Formation of “The Better Halves” at Muskego Moose Lodge",
            "description": "When Muskego Moose Lodge 1057 was instituted in 1966, the wives of the members met in the back room of Bob & Lee’s tavern as a social get-together while Lodge members held their meetings. Gradually the women decided to give themselves a name and meet on the 2nd and 4th Wednesday – the same time and day that the Lodge met. This ‘club’ was called “The Better Halves”.",
            "date": "1966"
        },
        {
            "title": "Formation of Women's Auxiliary - Muskego Chapter",
            "description": "In 1970 permission was granted by Mooseheart for the formation of a ladies auxiliary – a chapter. Fifty wives, mothers, sisters or daughters of Lodge members were need to complete a charter. Finally on May 3, 1970 – a rainy Sunday afternoon – members of Milwaukee Chapter 345 held an impressive ceremony at Bob & Lee’s instituting Muskego Chapter 1617 of the Women of the Moose.",
            "date": "1970"
        }
    ];


  return <>



  <div  className="@container">
              <div  className="@[480px]:p-4">
                <div
                    className="flex min-h-[80vh] flex-col gap-6 bg-cover bg-center bg-no-repeat @[480px]:gap-8 @[480px]:rounded-xl items-center justify-center p-4"
                    style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.4) 100%), url("img/moose-history-teacher.jpeg")`, backgroundPositionY: "top" }}
                >
                  <div  className="flex flex-col gap-2 text-center">
                    <h1 className="text-white text-4xl font-black leading-tight tracking-[-0.033em] @[480px]:text-5xl @[480px]:font-black @[480px]:leading-tight @[480px]:tracking-[-0.033em]">
                      History of Moose Lodge 1717
                    </h1>
                    <h2  className="text-white text-sm font-normal leading-normal @[480px]:text-base @[480px]:font-normal @[480px]:leading-normal">
                      A fraternal organization that supports our community and each other
                    </h2>
                  </div>

                </div>
              </div>
            </div>


            <div className="sm:px-1 md:px-20 lg:px-40 justify-center py-5">



  

            <h2  className="text-[#1b140e] text-[22px] font-bold leading-tight tracking-[-0.015em] px-4 pb-3 pt-5">Moose History</h2>

<div  className="grid grid-cols-[40px_1fr] gap-x-2 px-4">

  {timeline.map((item, index) => (
      <>
          <div  className="flex flex-col items-center gap-1 pb-3" key={index}>
              <div  className="w-[1.5px] bg-[#e7dbd0] h-2"></div>
              <div className="bg-center bg-no-repeat aspect-square bg-cover rounded-full size-6" style={{ backgroundImage: 'url("https://cdn.usegalileo.ai/sdxl10/9e4e2e67-34da-49f2-8456-bcf9d932a48d.png")' }}></div>
          </div>
          <div  className="flex flex-1 flex-col py-3">
              <p  className="text-[#1b140e] text-base font-medium leading-normal">{item.title}</p>
              <p  className="text-[#1b140e] text-base font-normal leading-normal">{item.description}</p>
              <p  className="text-[#97704e] text-base font-normal leading-normal">{item.date}</p>
          </div>
      </>
  ))}


</div>



            <h2 className="text-[#1b140e] text-[22px] font-bold leading-tight tracking-[-0.015em] px-4 pb-3 pt-5">Our History</h2>
            <p  className="text-[#1b140e] text-base font-normal leading-normal pb-3 pt-1 px-4">
              Moose Lodge 1717 has been a part of the Cocoa, FL community for over 100 years. We are a chapter of the Loyal Order of Moose, a fraternal and service organization
              formed in 1953. Our lodge was established in 1953 and has been a haven for members and guests ever since.



            </p>

            <h2 className="text-[#1b140e] text-[22px] font-bold leading-tight tracking-[-0.015em] px-4 pb-3 pt-5">Our Mission</h2>
            <p  className="text-[#1b140e] text-base font-normal leading-normal pb-3 pt-1 px-4">
              Our mission is to provide a family-friendly environment where members can come together for fun, fellowship, and community service. We are dedicated to helping those
              in need, supporting local charities, and making a positive impact in the West Seattle area.
            </p>

            <h2 className="text-[#1b140e] text-[22px] font-bold leading-tight tracking-[-0.015em] px-4 pb-3 pt-5">Our Values</h2>
            <p  className="text-[#1b140e] text-base font-normal leading-normal pb-3 pt-1 px-4">
              Moose Lodge 1717 is committed to the values of community, compassion, and camaraderie. We believe in the power of people working together to make the world a better
              place. Our members are united by a common desire to serve others and create a welcoming space for all.
            </p>

            <h2 className="text-[#1b140e] text-[22px] font-bold leading-tight tracking-[-0.015em] px-4 pb-3 pt-5">Affiliation</h2>
            <p  className="text-[#1b140e] text-base font-normal leading-normal pb-3 pt-1 px-4">
              Moose Lodge 1717 is proud to be a part of the Moose International Organization. This global network of lodges shares our commitment to community service, charitable
              giving, and social fellowship. Together, we are able to make a greater impact and support causes that matter most to our members and our communities.
            </p>

            </div>
  
  </>;
};

export default History;
