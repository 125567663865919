import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { xml2json } from 'xml-js';
import Modal from 'react-modal';

Modal.setAppElement('#root'); // Replace '#root' with the id of your main app element if it's different

const YouTubeChannel = () => {
  const [videos, setVideos] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    const fetchVideos = async () => {
      try {
        const formData = new FormData();
        formData.append('url', 'https://www.youtube.com/feeds/videos.xml?channel_id=UCyZIB6MC6ZmdgO4Elze5ewA');

        const response = await axios.post('https://www.wilsonstudioweb.com/proxy.cfc?method=get', formData, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        });


        const sanitizedData = response.data.replace(/&(?!(amp;|lt;|gt;|quot;|#39;))/g, '&amp;');
        const json = JSON.parse(xml2json(sanitizedData, { compact: true, spaces: 4 }));
        const entries = json.feed.entry;

        if (entries) {
          const videoItems = entries.slice(0, 50).map((entry) => ({
            id: entry['yt:videoId']._text,
            title: entry.title._text,
            thumbnail: entry['media:group']['media:thumbnail']._attributes.url,
            published: entry.published._text,
          }));
          setVideos(videoItems);
        } else {
          console.error('No videos found');
          setError('No videos found');
        }
      } catch (error) {
        console.error('Error fetching videos', error);
        setError('Error fetching videos');
      }
    };

    fetchVideos();
  }, []);

  const openModal = (video) => {
    setSelectedVideo(video);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedVideo(null);
    setModalIsOpen(false);
  };

  return (
    <>
      <div className="@container">
        <div className="@[480px]:p-4 overflow-hidden">
          <div
            className="overflow-visible relative flex min-h-[80vh] flex-col gap-6 bg-cover bg-center bg-no-repeat items-center justify-center p-4"
            style={{
              background: `linear-gradient(rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.4) 100%), url(${"img/moose-youtube.jpeg"})`,
              backgroundPositionX: 'center',
              backgroundPositionY: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
            }}
          >
            <div className="p-20 flex flex-col gap-2 text-center z-10 absolute animate__animated  animate__backInDown">
              <h1 className="text-shadow opacity-85 text-white text-4xl font-black leading-tight tracking-[-0.033em] @[480px]:text-5xl @[480px]:font-black @[480px]:leading-tight @[480px]:tracking-[-0.033em]">
                Moose International’s YouTube
              </h1>
              <h2 className="text-shadow text-white text-sm font-normal leading-normal @[480px]:text-base @[480px]:font-normal @[480px]:leading-normal">
                Check out the latest videos from Moose International’s official YouTube channel for the most current information.
              </h2>
            </div>
          </div>
        </div>
      </div>

      <div className="sm:px-1 md:px-20 lg:px-40 justify-center py-5">
        <div className="container mx-auto py-8">
          <div className="text-sm mb-4">
            Stay up-to-date with the latest developments and insights from Moose International! To get the most current
            information, we invite you to check out the{' '}
            <a href="https://www.youtube.com/@moosefraternity/videos" target="_new" className="mr-1 underline">
              Official YouTube Channel
            </a>{' '}
            where you can find a collection of engaging videos that showcase our organization’s initiatives, events, and
            more. Our channel is regularly updated with new content, so be sure to subscribe and hit the notification
            bell to stay informed about what’s happening within our community.
          </div>

          {error ? (
            <div className="text-red-500">{error}</div>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
              {videos.length > 0 ? (
                videos.map((video) => (
                  <div
                    key={video.id}
                    className="bg-white rounded-lg cursor-pointer text-gray-500 hover:text-[#003366]"
                    onClick={() => openModal(video)}
                  >
                    <img className="w-full h-auto rounded-md" src={video.thumbnail} alt={video.title} />
                    <p className="mt-2 text-xs font-medium">{video.title}</p>
                    <p className="mt-2 text-xs font-medium">{new Date(video.published).toLocaleDateString()}</p>
                  </div>
                ))
              ) : (
                <p>No videos to display</p>
              )}
            </div>
          )}
        </div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Video Modal"
          className="modal"
          overlayClassName="overlay outline-none"
        >
          {selectedVideo && (
            <div className="h-full">
              <div className="relative rounded overflow-hidden outline-none p-10 h-full ">
                <iframe
                  src={`https://www.youtube.com/embed/${selectedVideo.id}?autoplay=1`}
                  title={selectedVideo.title}
                  border="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  className="video outline-none"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          )}
        </Modal>
      </div>
    </>
  );
};

export default YouTubeChannel;
