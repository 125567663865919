import React, { useState, useEffect } from "react";
const Menu = () => {
  let kitchen_menu = [
    {
      category: "Soups & Salad",
      items: [
        {
          title: "Chili Cup",
          description: "A hearty cup of chili.",
          price: "$5.00",
          prompt: "Generate a thumbnail of a hearty cup of chili, steaming hot, garnished with fresh herbs, served in a rustic ceramic cup.",
          img: "2024-06-27_16-55-52_5618.png",
        },
        {
          title: "Chili Bowl",
          description: "A generous bowl of chili.",
          price: "$7.00",
          prompt: "Generate a thumbnail of a generous bowl of chili, rich and hearty, garnished with fresh herbs, served in a large rustic bowl.",
          img: "2024-06-27_17-06-24_8135.png",
        },
        {
          title: "Soup Of Day Cup",
          description: "Our daily soup served in a cup.",
          price: "$5.00",
          prompt: "Generate a thumbnail of a daily soup served in a cup, with steam rising, garnished with fresh herbs, in a cozy ceramic cup.",
          img: "2024-06-27_17-07-35_8189.png",
        },
        {
          title: "Soup Of Day Bowl",
          description: "Our daily soup served in a bowl.",
          price: "$7.00",
          prompt: "Generate a thumbnail of a daily soup served in a bowl, steaming hot, garnished with fresh herbs, in a large ceramic bowl.",
          img: "2024-06-27_17-09-21_3926.png",
        },
        {
          title: "Garden Salad",
          description: "Fresh mixed greens with assorted vegetables.",
          price: "$5.00",
          prompt: "Generate a thumbnail of a fresh garden salad, with mixed greens and assorted vegetables, vibrant and colorful.",
          img: "2024-06-27_17-10-43_2901.png",
        },
      ],
    },
    {
      category: "Appetizers and Snacks",
      items: [
        {
          title: "Mozzarella Sticks",
          description: "Breaded mozzarella cheese sticks served with marinara sauce.",
          price: "$8.00",
          prompt: "Generate a thumbnail of breaded mozzarella cheese sticks, golden brown, served with marinara sauce.",
          img: "2024-06-27_17-11-45_9559.png",
        },
        {
          title: "Jalapeno Poppers",
          description:
            "Jalapeno peppers stuffed with cheese, breaded, and fried.",
          price: "$8.00",
          prompt: "Generate a thumbnail of jalapeno poppers, stuffed with cheese, breaded, and fried, with a side of dipping sauce.",
          img: "2024-06-27_17-13-10_7645.png",
        },
        {
          title: "Chicken Fingers",
          description:  "Crispy chicken fingers served with your choice of dipping sauce.",
          price: "$7.00",
          prompt: "Generate a thumbnail of crispy chicken fingers, golden brown, served with a variety of dipping sauces.",
          img: "2024-06-27_17-13-56_3586.png",
        },
        {
          title: "Fried Mushrooms",
          description: "Breaded and fried mushrooms served with a dipping sauce.",
          price: "$8.00",
          prompt: "Generate a thumbnail of breaded and fried mushrooms, served with a creamy dipping sauce.",
          img: "2024-06-27_17-15-07_3501.png",
        },
        {
          title: "Potato Skins",
          description: "Crispy potato skins loaded with cheese and bacon bits.",
          price: "$8.00",
          prompt: "Generate a thumbnail of crispy potato skins, loaded with melted cheese and bacon bits.",
          img: "2024-06-27_17-15-47_6143.png",
        },
        {
          title: "Pretzel Bites",
          description: "Soft pretzel bites served with mustard or cheese dipping sauce.",
          price: "$6.00",
          prompt: "Generate a thumbnail of soft pretzel bites, served with mustard or cheese dipping sauce.",
          img: "2024-06-27_17-17-14_4873.png",
        },
        {
          title: "Crinkle Cut Fries",
          description: "Classic crinkle cut fries, crispy and delicious.",
          price: "$5.00",
          prompt: "Generate a thumbnail of classic crinkle cut fries, crispy and golden brown.",
          img: "2024-06-27_17-19-18_6444.png",
        },
        {
          title: "Beer Battered Onion Rings",
          description: "Thick-cut onion rings battered and fried to perfection.",
          price: "$5.00",
          prompt: "Generate a thumbnail of thick-cut onion rings, beer battered and fried to perfection.",
          img: "2024-06-27_17-19-57_6755.png",
        },
      ],
    },
    {
      category: "Chicken Wings",
      items: [
        {
          title: "Jumbo Chicken Wings",
          description: "Served with Ranch / Blue Cheese, Carrots & Salary. Sauces: BBQ, Garlic Parmesan, Sweet Thai, Teriyaki, Buffalo, Mango Habenero, 3 Alarm Hot Sauce. Dry Rubs: Lemon Pepper or Cajun",
          price: "6 for $8.00, 12 for $14.00",
          prompt: "Generate a thumbnail of jumbo chicken wings, served with Ranch and Blue Cheese, carrots, celery, various sauces, and dry rubs.",
          img: "2024-06-27_17-20-57_2971.png",
        },
      ],
    },
    {
      category: "Gourmet Burgers / Sliders / Dogs",
      items: [
        {
          title: "Cheeseburger Sliders",
          description: "Mini cheeseburgers served with your choice of toppings.",
          price: "$12.00",
          prompt: "Generate a thumbnail of mini cheeseburgers, with melted cheese and fresh toppings, served on small buns.",
          img: "2024-06-27_17-21-48_7523.png",
        },
        {
          title: "Chicken Sliders",
          description: "Juicy chicken sliders with fresh toppings.",
          price: "$11.00",
          prompt: "Generate a thumbnail of juicy chicken sliders, topped with fresh vegetables and served on small buns.",
          img: "2024-06-27_17-22-38_1971.png",
        },
        {
          title: "Melvin Dog",
          description: "A classic hot dog with your choice of condiments.",
          price: "$6.00",
          prompt: "Generate a thumbnail of a classic hot dog, with various condiments, served in a soft bun.",
          img: "2024-06-27_17-24-08_6879.png",
        },
        {
          title: "Chili Cheese Dog",
          description: "A hot dog topped with chili and melted cheese.",
          price: "$8.00",
          prompt: "Generate a thumbnail of a hot dog topped with chili and melted cheese, served in a soft bun.",
          img: "2024-06-27_17-26-43_1542.png",
        },
        {
          title: "Hamburger",
          description: "A classic hamburger served with lettuce, tomato, and onion. Add cheese for $2.00, add bacon for $1.00.",
          price: "$9.00",
          prompt: "Generate a thumbnail of a classic hamburger, with lettuce, tomato, and onion, served in a soft bun.",
          img: "2024-06-27_17-27-46_3796.png",
        },
        {
          title: "Garlic Mushroom Swiss Burger",
          description: "A savory burger topped with garlic mushrooms and Swiss cheese.",
          price: "$14.00",
          prompt: "Generate a thumbnail of a savory burger, topped with garlic mushrooms and Swiss cheese, served in a soft bun.",
          img: "2024-06-27_17-29-30_5332.png",
        },
      ],
    },
    {
      category: "Nachos / Chips & Dips",
      items: [
        {
          title: "Chili and Cheese Nachos",
          description: "Tortilla chips topped with chili and melted cheese.",
          price: "$10.00",
          prompt: "Generate a thumbnail of tortilla chips topped with chili and melted cheese, colorful and delicious.",
          img: "2024-06-27_17-33-52_1014.png",
        },
        {
          title: "Shredded Chicken Nachos",
          description: "Tortilla chips topped with shredded chicken and cheese.",
          price: "$11.00",
          prompt: "Generate a thumbnail of tortilla chips topped with shredded chicken and melted cheese, vibrant and appetizing.",
          img: "2024-06-27_17-35-28_2406.png",
        },
        {
          title: "Shrimp Nachos",
          description: "Tortilla chips topped with seasoned shrimp and cheese.",
          price: "$13.00",
          prompt: "Generate a thumbnail of tortilla chips topped with seasoned shrimp and melted cheese, colorful and appetizing.",
          img: "2024-06-27_17-37-00_2128.png",
        },
        {
          title: "Buffalo Chicken Dip",
          description: "Spicy buffalo chicken dip served with tortilla chips.",
          price: "$12.00",
          prompt: "Generate a thumbnail of spicy buffalo chicken dip, served with tortilla chips.",
          img: "2024-06-27_17-41-08_5364.png",
        },
        {
          title: "Spinach Artichoke Dip",
          description: "Creamy spinach and artichoke dip served with tortilla chips.",
          price: "$8.00",
          prompt: "Generate a thumbnail of creamy spinach and artichoke dip, served with tortilla chips.",
          img: "2024-06-27_17-43-21_1689.png",
        },
        {
          title: "Smoked Wahoo Fish Dip",
          description: "Smoked Wahoo fish dip served with tortilla chips.",
          price: "$9.00",
          prompt: "Generate a thumbnail of smoked Wahoo fish dip, served with tortilla chips.",
          img: "2024-06-27_17-45-42_3302.png",
        },
        {
          title: "Chips & Cheese / Salsa",
          description: "Tortilla chips served with melted cheese or salsa.",
          price: "$6.00",
          prompt: "Generate a thumbnail of tortilla chips, served with melted cheese or salsa.",
          img: "2024-06-27_17-47-53_8734.png",
        },
      ],
    },
    {
      category: "Sweets and Treats",
      items: [
        {
          title: "Raspberry / Caramel Sundae",
          description: "Creamy vanilla ice cream topped with your choice of raspberry or caramel sauce.",
          price: "$5.00",
          prompt: "Generate a thumbnail of creamy vanilla ice cream topped with raspberry or caramel sauce, in a sundae glass.",
          img: "2024-06-27_17-48-31_3511.png",
        },
        {
          title: "Xango Fried Cheesecake",
          description: "Creamy cheesecake wrapped in a tortilla shell, fried to a golden brown, and dusted with cinnamon sugar.",
          price: "$8.00",
          prompt: "Generate a thumbnail of creamy cheesecake wrapped in a tortilla shell, fried to a golden brown, dusted with cinnamon sugar.",
          img: "2024-06-27_17-50-53_3585.png",
        },
        {
          title: "Chocolate Chip Cookies",
          description: "Freshly baked chocolate chip cookies, warm and gooey.",
          price: "$3.00",
          prompt: "Generate a thumbnail of freshly baked chocolate chip cookies, warm and gooey.",
          img: "2024-06-27_17-52-35_3041.png",
        },
        {
          title: "Ask about Heathers cake of the week!",
          description: "Delicious homemade cake creations by Heather, changing weekly. Ask your server for details.",
          price: "$5.00",
          prompt: "Generate a thumbnail of a delicious homemade cake creation by Heather, with a variety of toppings and decorations.",
          img: "2024-06-27_17-54-47_8395.png",
        },
      ],
    },
  ];

  const draftBeer = [
    { name: "Hurricane Reef", size: "12oz", price: "$2.50" },
    { name: "Bud Light", size: "12oz", price: "$3.00" },
    { name: "Michelob ULTRA", size: "12oz", price: "$3.00" },
  ];

  const bottledBeer = [
    { name: "Bud", size: "12oz", price: "$3.00" },
    { name: "Bud Light", size: "12oz", price: "$3.00" },
    { name: "Bud Light Lime", size: "12oz", price: "$3.00" },
    { name: "Caribe Pineapple", size: "12oz", price: "$2.50" },
    { name: "Coors Light", size: "12oz", price: "$2.50" },
    { name: "Corona", size: "12oz", price: "$2.50" },
    { name: "Heineken", size: "12oz", price: "$2.50" },
    { name: "LandShark", size: "12oz", price: "$2.50" },
    { name: "Miller High Life", size: "12oz", price: "$2.50" },
    { name: "Miller Lite", size: "12oz", price: "$2.50" },
    { name: "Michelob Ultra", size: "12oz", price: "$2.50" },
    { name: "Modelo", size: "12oz", price: "$2.50" },
    { name: "Shock Top", size: "12oz", price: "$2.50" },
    { name: "Stella Artois", size: "12oz", price: "$2.50" },
    { name: "Stella Artois - Non Alcoholic", size: "12oz", price: "$2.50" },
    { name: "Yuengling", size: "12oz", price: "$2.50" },
  ];

  const buckets = [
    { name: "Domestic", size: "Five 12oz Bottles", price: "$13.50" },
    { name: "Import", size: "Five 12oz Bottles", price: "$16.00" }
  ];

  const cannedBeer = [
    { name: "Bud", size: "12oz", price: "$3.00" },
    { name: "Bud Light", size: "12oz", price: "$3.00" },
    { name: "Bud Light Orange", size: "12oz", price: "$3.00" },
    { name: "Busch", size: "12oz", price: "$3.00" },
    { name: "Coors Banquet", size: "12oz", price: "$3.00" },
    { name: "Miller Light", size: "12oz", price: "$3.00" },
    { name: "Pabst Blue Ribbon", size: "12oz", price: "$2.50" },
  ];

  /*
  const mixedDrinks = [
    { name: "Mojito", price: "$5.00" },
    { name: "Margarita", price: "$5.00" },
    { name: "Old Fashioned", price: "$6.00" },
    { name: "Martini", price: "$6.00" },
    { name: "Cosmopolitan", price: "$6.00" }
  ];
*/

  const sodas = [
    { name: "Coke", size: "12oz", price: "$1.00" },
    { name: "Diet Coke", size: "12oz", price: "$1.00" },
    { name: "Sprite", size: "12oz", price: "$1.00" },
    { name: "Fanta", size: "12oz", price: "$1.00" },
    { name: "Dr Pepper", size: "12oz", price: "$1.00" },
  ];

  const wines = [
    {
      name: "Pinot Grigio",
      size: "12oz",
      description:
        "A light and crisp white wine with hints of citrus and green apple, perfect for warm days.",
      price: "$4.00",
    },
    {
      name: "Chardonnay",
      size: "12oz",
      description:
        "A full-bodied white wine with flavors of oak, butter, and tropical fruits like pineapple and mango.",
      price: "$4.00",
    },
    {
      name: "Moscato",
      size: "12oz",
      description:
        "A sweet and fruity white wine with notes of peach, orange blossom, and honeysuckle.",
      price: "$4.00",
    },
    {
      name: "White Zinfandel",
      size: "12oz",
      description:
        "A sweet and slightly fruity rosé with flavors of strawberry, raspberry, and melon.",
      price: "$4.00",
    },
    {
      name: "Merlot",
      size: "12oz",
      description:
        "A smooth and medium-bodied red wine with flavors of blackberry, plum, and a hint of chocolate.",
      price: "$4.00",
    },
    {
      name: "Cabernet",
      size: "12oz",
      description:
        "A robust and full-bodied red wine with notes of black currant, cedar, and dark chocolate.",
      price: "$4.00",
    },
    {
      name: "White Zinfandel",
      size: "12oz",
      description:
        "A sweet and slightly fruity rosé with flavors of strawberry, raspberry, and melon.",
      price: "$4.00",
    },
    {
      name: "Champagne",
      size: "12oz",
      description:
        "A sparkling wine with a delicate balance of citrus, almond, and brioche flavors, perfect for celebrations.",
      price: "$4.00",
    },
  ];

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const openModal = (img) => {
    setSelectedImage(img);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedImage("");
  };

  const handleBackgroundClick = (event) => {
    if (event.target === event.currentTarget) {
      closeModal();
    }
  };



  const images = [
    'img/moose-beer.jpeg',
    'img/thedude-beer.jpeg',
    'img/moose_bar_1.png'
  ];
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fade, setFade] = useState(false);
  const [activeImage, setActiveImage] = useState(images[0]);

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(true);
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        setActiveImage(images[(currentIndex + 1) % images.length]);
        setFade(false);
      }, 3000); // Duration of the fade transition
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(interval);
  }, [currentIndex, images]);


  useEffect(() => {

    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        closeModal();
      }
    };

    if (modalOpen) {
      document.addEventListener("keydown", handleKeyDown);
    } else {
      document.removeEventListener("keydown", handleKeyDown);
    }

    // Clean up event listener on component unmount
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [modalOpen]);

  return (
    <>
      <div className="@container">
        <div className="@[480px]:p-4">
          <div
            className="flex min-h-[80vh] flex-col gap-6  @[480px]:gap-8 @[480px]:rounded-xl items-center justify-center p-4  bg-center bg-no-repeat"
            style={{
              background: `linear-gradient(rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.4) 100%), url(${"img/moose-kitchen2.jpeg"}) center center no-repeat `,
              backgroundSize: "cover",
              backgroundPositionX: "center",
              backgroundPositionY: "center",
              backgroundRepeat: "no-repeat",
              backgroundAttachment: "fixed",
            }}
          >
            <div className="flex flex-col gap-2 text-center">
              <h1 className="text-white text-4xl font-black leading-tight tracking-[-0.033em] @[480px]:text-5xl @[480px]:font-black @[480px]:leading-tight @[480px]:tracking-[-0.033em]">
                Moose Lodge 1717 Kitchen
              </h1>
              <h2 className="text-white text-sm font-normal leading-normal @[480px]:text-base @[480px]:font-normal @[480px]:leading-normal">
                Our kitchen serves food from 5-9 PM on Wednesdays, Fridays, and
                Saturdays. We also feature daily specials.
              </h2>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-[#fff]">
        <div className="sm:px-1 md:px-20 lg:px-40 justify-center py-5">
          <h2 className=" text-[#1b140e] text-[22px] font-bold leading-tight tracking-[-0.015em] px-4 pb-3 pt-5">
            Specials
          </h2>
          <div className="grid grid-cols-[repeat(auto-fit,minmax(158px,1fr))] gap-3 p-4">
            <div className="flex flex-col gap-3 pb-3">
              <div
                className="w-full bg-center bg-no-repeat aspect-video bg-cover rounded-xl"
                style={{ backgroundImage: 'url("img/meatloaf.jpeg")' }}
              ></div>
              <div>
                <p className="text-[#1b140e] text-base font-medium leading-normal">
                  Wednesday
                </p>
                <p className="text-[#97704e] text-sm font-normal leading-normal">
                  Meatloaf Dinner $14.00
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-3 pb-3">
              <div
                className="w-full bg-center bg-no-repeat aspect-video bg-cover rounded-xl"
                style={{ backgroundImage: 'url("img/pierogies.jpeg")' }}
              ></div>
              <div>
                <p className="text-[#1b140e] text-base font-medium leading-normal">
                  Friday
                </p>
                <p className="text-[#97704e] text-sm font-normal leading-normal">
                  Kielbasa & Pierogies $14.00
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-3 pb-3">
              <div
                className="w-full bg-center bg-no-repeat aspect-video bg-cover rounded-xl"
                style={{ backgroundImage: 'url("img/steak.jpeg")' }}
              ></div>
              <div>
                <p className="text-[#1b140e] text-base font-medium leading-normal">
                  Saturday
                </p>
                <p className="text-[#97704e] text-sm font-normal leading-normal">
                  Steak Dinner $14.00
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-[#efefef] pb-10">
        <div className="sm:px-1 md:px-20 lg:px-40 justify-center py-5">
          <h2 className="text-[#1b140e] text-[22px] font-bold leading-tight tracking-[-0.015em] px-4 pb-3 pt-5 mt-[20px]">
            Kitchen Staff
          </h2>
          <div className="flex items-center gap-4 bg-[#fcfaf8] px-4 min-h-[72px] py-2">
            <div
              className="bg-center bg-no-repeat aspect-square bg-cover rounded h-14 w-fit"
              style={{ backgroundImage: 'url("img/moose-chef-circle.png")' }}
            ></div>
            <div className="flex flex-col justify-center">
              <p className="text-[#1b140e] text-base font-medium leading-normal line-clamp-1">
                Megan Frederick
              </p>
              <p className="text-[#97704e] text-sm font-normal leading-normal line-clamp-2">
                Head Chef
              </p>
            </div>
          </div>
          <div className="flex items-center gap-4 bg-[#fcfaf8] px-4 min-h-[72px] py-2">
            <div
              className="bg-center bg-no-repeat aspect-square bg-cover rounded-full h-14 w-fit"
              style={{
                backgroundImage:
                  'url("https://cdn.usegalileo.ai/sdxl10/7d449c2c-5d43-40ad-b9f7-10b164ae72de.png")',
              }}
            ></div>
            <div className="flex flex-col justify-center">
              <p className="text-[#1b140e] text-base font-medium leading-normal line-clamp-1">
                Heather Halfan
              </p>
              <p className="text-[#97704e] text-sm font-normal leading-normal line-clamp-2">
                Kitchen Assistant
              </p>
            </div>
          </div>

          {kitchen_menu.map((menu_cat, index) => (
            <div key={index}>
              <h2 className="text-[#1b140e] text-[22px] font-bold leading-tight tracking-[-0.015em] px-4 pb-3 pt-5">
                {menu_cat.category}
              </h2>
              {menu_cat.items.map((item, index) => (
                <div
                  key={index}
                  className="flex items-start gap-4 bg-[#fcfaf8] px-4 min-h-[72px] py-2 justify-between cursor-pointer transition-all hover:border hover:scale-105 hover:shadow-lg hover:rounded-md"
                  onClick={() => item.img && openModal("img/menu/" + item.img)}
                >
                  <div className="flex items-start gap-4">
                    <div
                      className="text-[#1b140e] flex items-center justify-center rounded-lg bg-[#f3ede7] shrink-0 size-12"
                      data-icon="Hamburger"
                      data-size="24px"
                      data-weight="regular"
                      onClick={() =>
                        item.img && openModal("img/menu/" + item.img)
                      }
                    >
                      {item.img ? (
                        <img
                          className="rounded-lg"
                          src={"img/menu/" + item.img}
                        />
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24px"
                          height="24px"
                          fill="currentColor"
                          viewBox="0 0 256 256"
                        >
                          <path d="M48.07,104H207.93a16,16,0,0,0,15.72-19.38C216.22,49.5,176,24,128,24S39.78,49.5,32.35,84.62A16,16,0,0,0,48.07,104ZM128,40c39.82,0,74.21,20.61,79.93,48H48.07L48,87.93C53.79,60.61,88.18,40,128,40ZM229.26,152.48l-41.13,15L151,152.57a8,8,0,0,0-5.94,0l-37,14.81L71,152.57a8,8,0,0,0-5.7-.09l-44,16a8,8,0,0,0,5.47,15L40,178.69V184a40,40,0,0,0,40,40h96a40,40,0,0,0,40-40v-9.67l18.73-6.81a8,8,0,1,0-5.47-15ZM200,184a24,24,0,0,1-24,24H80a24,24,0,0,1-24-24V172.88l11.87-4.32L105,183.43a8,8,0,0,0,5.94,0l37-14.81,37,14.81a8,8,0,0,0,5.7.09l9.27-3.37ZM16,128a8,8,0,0,1,8-8H232a8,8,0,0,1,0,16H24A8,8,0,0,1,16,128Z" />
                        </svg>
                      )}
                    </div>
                    <div className="flex flex-col justify-center vertical-align-top items-start">
                      <p className="text-[#1b140e] text-base font-medium leading-normal line-clamp-1">
                        {item.title}
                      </p>
                      <p
                        className="text-[#97704e] text-xs font-normal leading-normal line-clamp-4"
                        dangerouslySetInnerHTML={{ __html: item.description }}
                      />
                    </div>
                  </div>
                  <div className="flex shrink-0 items-start">
                    <p
                      className="flex-item text-[#1b140e] text-base font-normal leading-normal items-start text-right align-text-top"
                      dangerouslySetInnerHTML={{ __html: item.price }}
                    />
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>

      <div className="relative flex min-h-[50vh] max-h-[50vh] md:min-h-[90vh] md:max-h-[90vh] flex-col items-center justify-center p-20 text-white shadow-lg">
      <div
        className={`absolute inset-0 transition-opacity duration-1000 ${fade ? 'opacity-0' : 'opacity-100'}`}
        style={{
          background: `linear-gradient(rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.4) 100%), url(${activeImage}) center center no-repeat`,
          backgroundSize: 'cover',
          backgroundPositionX: 'center',
          backgroundPositionY: '20%',
          backgroundRepeat: 'no-repeat',
        }}
      ></div>
      <div
        className={`absolute inset-0 transition-opacity duration-1000 ${fade ? 'opacity-100' : 'opacity-0'}`}
        style={{
          background: `linear-gradient(rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.4) 100%), url(${images[currentIndex]}) center center no-repeat`,
          backgroundSize: 'cover',
          backgroundPositionX: 'center',
          backgroundPositionY: '20%',
          backgroundRepeat: 'no-repeat',
        }}
      ></div>
      <div className="relative z-10 opacity-90 text-2xl md:text-4xl text-center font-bold uppercase animate__animated animate__zoomIn">
        Beer Menu
      </div>
      <p className="relative z-10 text-base text-center font-bold opacity-90 max-w-[640px] animate__animated animate__zoomIn">
        Savor the crisp, refreshing essence of crafted beer, a perfect balance that delights with every sip.
      </p>
    </div>

      <div className="sm:px-1 md:px-20 lg:px-40 justify-center py-5">
        <div className="columns-1 lg:columns-3 md:columns-2 gap-4 ">
          {/* Draft Beer */}
          <div className="px-1">
            <h2 className="text-[#1b140e] text-[22px] font-bold leading-tight tracking-[-0.015em] px-4 pb-3 pt-5">
              Draft Beer
            </h2>
            {draftBeer.map((beer, index) => (
              <div
                key={index}
                className="flex items-center gap-4 bg-[#fcfaf8] px-4 min-h-[72px] py-2 justify-between"
              >
                <div className="flex flex-col justify-center">
                  <p className="text-[#1b140e] text-base font-medium leading-normal line-clamp-1">
                    {beer.name}
                  </p>
                  <p className="text-[#97704e] text-sm font-normal leading-normal line-clamp-2">
                    {beer.size}
                  </p>
                </div>
                <div className="shrink-0">
                  <p className="text-[#1b140e] text-base font-normal leading-normal">
                    {beer.price}
                  </p>
                </div>
              </div>
            ))}
          </div>

          {/* Canned Beer */}
          <div className="px-1">
            <h2 className="text-[#1b140e] text-[22px] font-bold leading-tight tracking-[-0.015em] px-4 pb-3 pt-5">
              Canned Beer
            </h2>
            {cannedBeer.map((beer, index) => (
              <div
                key={index}
                className="flex items-center gap-4 bg-[#fcfaf8] px-4 min-h-[72px] py-2 justify-between"
              >
                <div className="flex flex-col justify-center">
                  <p className="text-[#1b140e] text-base font-medium leading-normal line-clamp-1">
                    {beer.name}
                  </p>
                  <p className="text-[#97704e] text-sm font-normal leading-normal line-clamp-2">
                    {beer.size}
                  </p>
                </div>
                <div className="shrink-0">
                  <p className="text-[#1b140e] text-base font-normal leading-normal">
                    {beer.price}
                  </p>
                </div>
              </div>
            ))}
          </div>

          {/* Bottled Beer */}
          <div className="px-1">
            <h2 className="text-[#1b140e] text-[22px] font-bold leading-tight tracking-[-0.015em] px-4 pb-3 pt-5">
              Bottled Beer
            </h2>
            {bottledBeer.map((beer, index) => (
              <div
                key={index}
                className="flex items-center gap-4 bg-[#fcfaf8] px-4 min-h-[72px] py-2 justify-between"
              >
                <div className="flex flex-col justify-center">
                  <p className="text-[#1b140e] text-base font-medium leading-normal line-clamp-1">
                    {beer.name}
                  </p>
                  <p className="text-[#97704e] text-sm font-normal leading-normal line-clamp-2">
                    {beer.size}
                  </p>
                </div>
                <div className="shrink-0">
                  <p className="text-[#1b140e] text-base font-normal leading-normal">
                    {beer.price}
                  </p>
                </div>
              </div>
            ))}
          </div>



          {/* Bottled Beer */}
          <div className="px-1">
            <h2 className="text-[#1b140e] text-[22px] font-bold leading-tight tracking-[-0.015em] px-4 pb-3 pt-5">
              Buckets of Beer
              <p className="text-sm font-normal">A bucket filled with a selection of chilled beers, perfect for sharing with friends or enjoying during a gathering.</p>
            </h2>
            {buckets.map((bucket, index) => (
              <div
                key={index}
                className="flex items-center gap-4 bg-[#fcfaf8] px-4 min-h-[72px] py-2 justify-between"
              >
                <div className="flex flex-col justify-center">
                  <p className="text-[#1b140e] text-base font-medium leading-normal line-clamp-1">
                    {bucket.name}
                  </p>
                  <p className="text-[#97704e] text-sm font-normal leading-normal line-clamp-2">
                    {bucket.size}
                  </p>
                </div>
                <div className="shrink-0">
                  <p className="text-[#1b140e] text-base font-normal leading-normal">
                    {bucket.price}
                  </p>
                </div>
              </div>
            ))}
          </div>





        </div>

</div>

<div className="py-6 sm:px-1 md:px-20 px-2 sm:px-10 md:px-20 lg:px-40 justify-center py-5 bg-[#ddd]">
            <div className="rounded-lg bg-[#fcfaf8] p-4 shadow-md">
        <table>
          <tr>
            <td width="180">
              <img src="img/drink-chip.png" />
            </td>
            <td className="p-4 text-lg">
              <h3 className="text-lg font-bold">Did You Know?</h3>
              <p className="text-sm text-[#97704e]">A drink chip is a small token that can be exchanged for a free
              pre-paid drink. They're often gifted as a form
              of promotion, reward, or as part of a membership benefit and can
              be redeemed at the bar.</p>

            </td>
          </tr>
        </table>
        </div>
</div>


          <div
            className="flex  min-h-[50vh] max-h-[50vh] md:min-h-[90vh] md:max-h-[90vh] flex-col items-center justify-center p-20  bg-center bg-no-repeat bg-fixed text-white shadow-lg"
            style={{
              background: `linear-gradient(rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.4) 100%), url(${"img/moose-tuxedo.jpeg"}) center center no-repeat `,
              backgroundSize: "cover",
              backgroundPositionX: "center",
              backgroundPositionY: "20%",
              backgroundRepeat: "no-repeat",
            }}
          >
              <div className="text-4xl text-center font-bold uppercase animate__animated  animate__zoomIn">Wine Menu</div> 
              <p className="text-base text-center font-bold opacity-90 max-w-[640px] animate__animated  animate__zoomIn">Indulge in a variety of classic and modern wines, promising a sophisticated and memorable tasting experience.</p>
          </div>

      <div className="bg-[#fff] shadow-md">
        <div className="sm:px-1 md:px-20 lg:px-40 justify-center py-5">

          {/* Wines */}
          <div className="px-1">

            {wines.map((wine, index) => (
              <div
                key={index}
                className="flex items-center gap-4 bg-[#fcfaf8] px-4 min-h-[72px] py-2 justify-between"
              >
                <div className="flex flex-col justify-center">
                  <p className="text-[#1b140e] text-base font-medium leading-normal line-clamp-1">
                    {wine.name}
                  </p>
                  <p className="text-[#97704e] text-sm font-normal leading-normal line-clamp-3">
                    {wine.description}
                  </p>
                </div>
                <div className="shrink-0">
                  <p className="text-[#1b140e] text-base font-normal leading-normal">
                    {wine.price}
                  </p>
                </div>
              </div>
            ))}
          </div>

</div>


          <div
            className="flex  min-h-[50vh] max-h-[50vh] md:min-h-[90vh] md:max-h-[90vh] flex-col items-center justify-center p-20  bg-center bg-no-repeat bg-fixed text-white shadow-lg"
            style={{
              background: `linear-gradient(rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.4) 100%), url(${"img/coca-moose.jpg"}) center center no-repeat `,
              backgroundSize: "cover",
              backgroundPositionX: "center",
              backgroundPositionY: "20%",
              backgroundRepeat: "no-repeat",
            }}
          >
              <div className="text-4xl text-center font-bold uppercase animate__animated  animate__zoomIn">Soft Drinks</div> 
              <p className="text-base text-center font-bold opacity-90 max-w-[640px] animate__animated  animate__zoomIn">Delight in the revitalizing and refreshing sensation of a perfect pick-me-up for any time of day.</p>
          </div>



        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-10">
          {/* Sodas */}
          <div className="px-1">
            <h2 className="text-[#1b140e] text-[22px] font-bold leading-tight tracking-[-0.015em] px-4 pb-3 pt-5">
              Sodas
            </h2>
            {sodas.map((soda, index) => (
              <div
                key={index}
                className="flex items-center gap-4 bg-[#fcfaf8] px-4 min-h-[72px] py-2 justify-between"
              >
                <div className="flex flex-col justify-center">
                  <p className="text-[#1b140e] text-base font-medium leading-normal line-clamp-1">
                    {soda.name}
                  </p>
                  <p className="text-[#97704e] text-sm font-normal leading-normal line-clamp-2">
                    {soda.size}
                  </p>
                </div>
                <div className="shrink-0">
                  <p className="text-[#1b140e] text-base font-normal leading-normal">
                    {soda.price}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {modalOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
          onClick={handleBackgroundClick}
        >
          <div className="">
            <button
              onClick={closeModal}
              className="absolute top-2 right-2 text-gray-600 hover:text-gray-900"
            >
              &times;
            </button>
            <img
              src={selectedImage}
              alt="Menu Item"
              className="bg-white p-4 m-[20px] rounded-lg box-border max-w-[90vw] max-h-[90vh] box-border"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Menu;
