import React, { useState } from 'react';
import axios from 'axios';

const EventForm = ({ fetchEvents }) => {
  const [event, setEvent] = useState({
    title: '',
    description: '',
    categoryID: '',
    lodgeID: '',
    eventDate: '',
    startTime: '',
    endTime: '',
    isRecurring: false,
    imageURL: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEvent({
      ...event,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    axios.post('http://localhost:5000/events', event, {
      headers: { Authorization: token }
    })
      .then(response => {
        console.log('Event added:', response.data);
        fetchEvents(); // Refresh the events list
      })
      .catch(error => {
        console.error('There was an error adding the event!', error);
      });
  };

  return (
    <form onSubmit={handleSubmit}>
       <input type="hidden" name="lodgeID" id="lodgeID" value="1717" />
      <h2 className="text-xl font-bold mb-6">Add Event</h2>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">


      <div className="">
          <label className="block text-gray-700 text-sm font-bold" htmlFor="categoryID">
            Event Category
          </label>
          <input
            placeholder="Event Category"
            type="text"
            name="categoryID"
            id="categoryID"
            value={event.categoryID}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-sm text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>

        <div className="md:col-span-2">
          <label className="block text-gray-700 text-sm font-bold " htmlFor="title">
            Title
          </label>
          <input
            placeholder="Title" 
            type="text"
            name="title"
            id="title"
            value={event.title}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3  text-sm text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="md:col-span-2">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="description">
            Description
          </label>
          <textarea
            placeholder="Description" 
            name="description"
            id="description"
            value={event.description}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3  text-sm text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>

        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="description">Start</label>

          <div className="flex shadow appearance-none border rounded w-full py-2 px-3  text-sm text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
            
            <input
              placeholder="Start Date"
              type="date"
              name="eventDate"
              id="eventDate"
              value={event.eventDate}
              onChange={handleChange}
              className="text-sm text-gray-700 leading-tight focus:outline-none focus:shadow-outline mr-2"
            />
            <input
              placeholder="Time"
              type="time"
              name="startTime"
              id="startTime"
              value={event.startTime}
              onChange={handleChange}
              className="text-sm text-gray-700 leading-tight focus:outline-none focus:shadow-outline"

            />
          </div>
        </div>

        <div>
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="description">End</label>

          <div className="shadow appearance-none border rounded w-full py-2 px-3  text-sm text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
            <input
              placeholder="End Date" 
              type="date"
              name="end_eventDate"
              id="end_eventDate"
              value={event.eventDate}
              onChange={handleChange}
              className="text-sm text-gray-700 leading-tight focus:outline-none focus:shadow-outline mr-4"
            />
            <input
              placeholder="End Time" 
              type="time"
              name="endTime"
              id="endTime"
              value={event.endTime}
              onChange={handleChange}
              className="text-sm text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
        </div>

        <div className="">
          <label className="block text-gray-700 text-sm font-bold " htmlFor="isRecurring">
            Is Recurring
          </label>
          <input
            placeholder="Is Recurring" 
            type="checkbox"
            name="isRecurring"
            id="isRecurring"
            checked={event.isRecurring}
            onChange={() => setEvent({ ...event, isRecurring: !event.isRecurring })}
            className="shadow appearance-none border rounded text-sm text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="md:col-span-2">
          <label className="block text-gray-700 text-sm font-bold " htmlFor="imageURL">
            Image URL
          </label>
          <input
            placeholder="Image URL" 
            type="text"
            name="imageURL"
            id="imageURL"
            value={event.imageURL}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-sm text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
      </div>
      <div className="flex items-center justify-between mt-4">
        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 text-sm text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Add Event
        </button>
      </div>
    </form>
  );
};

export default EventForm;
