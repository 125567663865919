import React from 'react';
import BecomeMember from '../components/BecomeMember';


const About = () => {

    return <>
    <div className="text-center align-center justify-center full-width mt-4">
      <center><img src="img/MLODGE-LOGO02.png"  className="text-center align-center justify-center" /></center>
  </div>

<div className="sm:px-1 md:px-20 lg:px-40 justify-center py-5">



  <h2  className="text-[#1b140e] text-[22px] font-bold leading-tight tracking-[-0.015em] px-4 pb-3 pt-5">About</h2>
  <p  className="text-[#1b140e] text-base font-normal leading-normal pb-3 pt-1 px-4">Loyal Order of Moose - 1717 unites its members in the bonds of fraternity benevolence and charity through a year-round schedule of social and recreational activities for the members and their families. Members participate in different activities and fundraisers such as dances, yard sales, auctions, and dinners to raise money for the charities they support. The organization is located at 221 Clearlake Rd in Cocoa, FL.</p>



  <h2  className="text-[#1b140e] text-[22px] font-bold leading-tight tracking-[-0.015em] px-4 pb-3 pt-5">Our Mission</h2>
  <p  className="text-[#1b140e] text-base font-normal leading-normal pb-3 pt-1 px-4">
  The mission of Moose International, Inc., is to strive for excellence in four areas: (i) a fraternal program and
community service within our lodge and chapter system known as the Loyal Order of The Moose and the
Women of the Moose; (ii) to provide a family environment and education for underprivileged children in need in
residence at Mooseheart, Illinois; (iii) to provide for the needs of senior members at Moosehaven, Florida; and,
(iv) to promote membership growth.
Moose International, Inc., provides opportunities for fraternal unity within its membership throughout the
United States, Canada, and Great Britain and Bermuda, and has as a basic tenet - excellence in volunteerism,
fraternalism, the promise of the finest living facilities at both Mooseheart and Moosehaven and continual
custodial service for those entrusted to our care.
Moose International, Inc., is committed to a positive leadership role in our communities as demonstrated by
the establishment of modern lodge facilities and family centers to host activities for the entire family, complete
and varied sports programs and assistance to our neighbors in need through community service programs. This is
accomplished through continuous leadership training programs that adhere to high ethical standards, excellence,
quality and total volunteer commitment by every lodge officer.
Moose International is a charitable, non-discriminatory, non-profit corporation funded primarily by members
of the Moose.

  </p>
  <h2  className="text-[#1b140e] text-[22px] font-bold leading-tight tracking-[-0.015em] px-4 pb-3 pt-5">Our Commitments</h2>
  <p  className="text-[#1b140e] text-base font-normal leading-normal pb-3 pt-1 px-4">



<ul class="list-disc list-inside py-4">
  <li class="mb-6">
    <strong class="text-lg font-bold inline">Community Service</strong>
    : Moose International is committed to giving back to communities through a variety of service programs. This includes establishing family centers and modern lodge facilities to host activities, sports programs, and assistance to those in need.
  </li>
  <li class="mb-6">
    <strong class="text-lg font-boldinline">Fraternal Program</strong>
    : Moose International’s fraternal program, the Loyal Order of Moose and the Women of the Moose, aims to provide a family environment and education for underprivileged children.
  </li>
  <li class="mb-6">
    <strong class="text-lg font-bold 2">Diversity</strong>
    : Moose International is committed to cultivating a culture of inclusion and connectedness, and does not discriminate based on race, color, ancestry, religion, sex, national origin, sexual orientation, age, citizenship, marital status, disability, gender identity, or veteran status.
  </li>
  <li class="mb-6">
    <strong class="text-lg font-bold ">Leadership</strong>
    : Moose International is committed to providing leadership training programs that adhere to high ethical standards, excellence, quality, and total volunteer commitment by every lodge officer.
  </li>
  <li class="mb-6">
    <strong class="text-lg font-bold ">Mooseheart Child City & School</strong>
    : Moose International members support Mooseheart, which cares for children in need from infants through high school.
  </li>
</ul>




  </p>
  <h2  className="text-[#1b140e] text-[22px] font-bold leading-tight tracking-[-0.015em] px-4 pb-3 pt-5">Our Future</h2>
  <p  className="text-[#1b140e] text-base font-normal leading-normal pb-3 pt-1 px-4">
    As we look ahead to the next 100 years, Moose Lodge 1717 remains dedicated to making a positive impact on the lives of those in our community. We will continue to
    support local charities, provide scholarships to deserving students, and offer a welcoming space for people of all ages to come together and enjoy life. We are
    grateful for the support of our members and friends, and we invite you to join us in our mission to make a difference.
  </p>

  <BecomeMember />

</div>




</>
};

export default About;
